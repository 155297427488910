import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { LESSON_TYPE } from '../../util/types';
import { getProperResponseOfListing } from '../ManageListingsPage/ManageListingsPage.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const LESSON_LISTINGS_REQUEST = 'app/SchoolInvitePage/LESSON_LISTINGS_REQUEST';
export const LESSON_LISTINGS_SUCCESS = 'app/SchoolInvitePage/LESSON_LISTINGS_SUCCESS'
export const LESSON_LISTINGS_ERROR = 'app/SchoolInvitePage/LESSON_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  lessonListings: [],
  lessonListingRequest: true,
  lessonListingError: false,
};

export default function SchoolInvitePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LESSON_LISTINGS_REQUEST:
      return { ...state, lessonListingRequest: true };
    case LESSON_LISTINGS_SUCCESS:
      return { ...state, lessonListings: payload, lessonListingRequest: false }
    case LESSON_LISTINGS_ERROR:
      return { ...state, lessonListingError: payload, lessonListingRequest: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const lessonListingRequest = () => ({
  type: LESSON_LISTINGS_REQUEST,
});

export const lessonListings = (data) => ({
  type: LESSON_LISTINGS_SUCCESS,
  payload: data,
})

export const lessonListingError = e => ({
  type: LESSON_LISTINGS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const queryLessonlistings = (params,config) => async (dispatch, getState, sdk) => {
  dispatch(lessonListingRequest())
  return sdk.listings.query(params).then(response => {
    const getProperResponse = getProperResponseOfListing(response, config);
    dispatch(lessonListings(getProperResponse));
    return response;
  }).catch((e) => {
    return dispatch(lessonListingError(storableError(e)));
  })
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      dispatch(queryLessonlistings({ 
        authorId: currentUser.id.uuid, 
        pub_learningType: LESSON_TYPE,  
        include: ['images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio), 
    }),config);
      return response;
    })
    .catch(e => {
      throw e;
    });
};



