import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { createSlug, parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { omit } from 'lodash';
import { onCreateMultipleNotification, onStripeCreateProduct } from '../../util/api';
import { queryInstructors } from '../SchoolInstructorPage/SchoolInstructorPage.duck';
import { DATE } from '../../util/types';
import { onAddInstructorsInSession } from '../SchoolDashBoardPage/SchoolDashBoardPage.duck';
import { getAllInstructorsData } from '../../util/data';
import { schoolInstructorFilterArray } from '../../config/configStrapiUrl';

const { UUID } = sdkTypes;

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

const getImageVariantInfo = listingImageConfig => {
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
    const aspectRatio = aspectHeight / aspectWidth;
    const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

    return {
        fieldsImage,
        imageVariants: {
            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        },
    };
};

// ================ Action types ================ //

export const FETCH_COURSE_LISTING_REQUEST = 'app/CreateCoursePage/FETCH_COURSE_LISTING_REQUEST';
export const FETCH_COURSE_LISTING_SUCCESS = 'app/CreateCoursePage/FETCH_COURSE_LISTING_SUCCESS';
export const FETCH_COURSE_LISTING_ERROR = 'app/CreateCoursePage/FETCH_COURSE_LISTING_ERROR';

export const UPLOAD_LISTING_IMAGE_REQUEST = 'app/CreateCoursePage/UPLOAD_LISTING_IMAGE_REQUEST';
export const UPLOAD_LISTING_IMAGE_SUCCESS = 'app/CreateCoursePage/UPLOAD_LISTING_IMAGE_SUCCESS';
export const UPLOAD_LISTING_IMAGE_ERROR = 'app/CreateCoursePage/UPLOAD_LISTING_IMAGE_ERROR';
export const CREATE_UPDATE_REQUEST = 'app/CreateCoursePage/CREATE_UPDATE_REQUEST'

export const REMOVE_LISTING_IMAGE = 'app/CreateCoursePage/REMOVE_LISTING_IMAGE';
export const EMPTY_COURSE_LISTING = 'app/CreateCoursePage/EMPTY_COURSE_LISTING';


const makeOwnlistingResponse = (response) => {
    const currentStock = response.data.included && response.data.included.length ? response.data.included.findIndex((st) => st.type == "stock") : -1;
    return { ...response.data.data, images: (response.data.included && response.data.included.length && response.data.included.filter((st) => st.type == "image")) || [], currentStock: currentStock >= 0 ? response.data.included[currentStock] : false }
}

// ================ Reducer ================ //

const initialState = {
    courseListingProgress: false,
    courseListingError: false,
    courseListing: false,
    uploadedImages: {},
    uploadedImageError: null,
    uploadedImagesOrder: [],
    removedImageIds: [],
    createUpdateRequest: false,
};

const CreateCoursePageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case EMPTY_COURSE_LISTING:
            return {
                ...state,
                courseListingProgress: false,
                courseListingError: false,
                courseListing: false,
                uploadedImages: {},
                uploadedImageError: null,
                uploadedImagesOrder: [],
                removedImageIds: [],
                createUpdateRequest: false,
            }
        case FETCH_COURSE_LISTING_REQUEST:
            return {
                ...state,
                courseListingProgress: true,
                courseListingError: false,
                courseListing: false
            };
        case FETCH_COURSE_LISTING_SUCCESS:
            return {
                ...state,
                courseListingProgress: false,
                courseListingError: false,
                courseListing: payload
            };
        case FETCH_COURSE_LISTING_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return {
                ...state,
                courseListingProgress: false,
                courseListing: false,
                courseListingError: payload,
            };

        case CREATE_UPDATE_REQUEST:
            return {
                ...state,
                createUpdateRequest: payload
            }
        case UPLOAD_LISTING_IMAGE_REQUEST: {
            // payload.params: { id: 'tempId', file }

            const uploadedImages = {
                ...state.uploadedImages,
                [payload.id]: { ...payload },
            };
            return {
                ...state,
                uploadedImages,
                uploadedImagesOrder: state.uploadedImagesOrder.concat([payload.id]),
                uploadedImageError: null,
            };
        }
        case UPLOAD_LISTING_IMAGE_SUCCESS: {
            // payload.params: { id: 'tempId', imageId: 'some-real-id', attributes, type }

            const { id, ...rest } = payload;
            const uploadedImages = { ...state.uploadedImages, [id]: { id, ...rest } };
            return { ...state, uploadedImages };
        }
        case UPLOAD_LISTING_IMAGE_ERROR: {
            // eslint-disable-next-line no-console
            const { id, error } = payload;
            const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);
            const uploadedImages = omit(state.uploadedImages, id);
            return { ...state, uploadedImagesOrder, uploadedImages, uploadedImageError: error };
        }
        case REMOVE_LISTING_IMAGE: {
            const id = payload.imageId;

            // Only mark the image removed if it hasn't been added to the
            // listing already
            const removedImageIds = state.uploadedImages[id]
                ? state.removedImageIds
                : state.removedImageIds.concat(id);

            // Always remove from the draft since it might be a new image to
            // an existing listing.
            const uploadedImages = omit(state.uploadedImages, id);
            const uploadedImagesOrder = state.uploadedImagesOrder.filter(i => i !== id);

            return { ...state, uploadedImages, uploadedImagesOrder, removedImageIds };
        }

        default:
            return state;
    }
};

export default CreateCoursePageReducer
// ================ Selectors ================ //

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryCourseListingRequest = () => ({
    type: FETCH_COURSE_LISTING_REQUEST
});

export const queryCourseListingSuccess = response => ({
    type: FETCH_COURSE_LISTING_SUCCESS,
    payload: response,
});

export const queryCourseListingError = e => ({
    type: FETCH_COURSE_LISTING_ERROR,
    payload: e,
});

// SDK method: listingImages.upload
export const uploadListingImageRequest = params => ({ type: UPLOAD_LISTING_IMAGE_REQUEST, payload: params });
export const uploadListingImageSuccess = params => ({ type: UPLOAD_LISTING_IMAGE_SUCCESS, payload: params });
export const uploadListingImageError = params => ({ type: UPLOAD_LISTING_IMAGE_ERROR, payload: params });

export const onCreateUpdateCourse = (params) => ({ type: CREATE_UPDATE_REQUEST, payload: params });

export const removeListingImage = imageId => ({
    type: REMOVE_LISTING_IMAGE,
    payload: { imageId },
});

export const emptyCourseListingData = () => ({
    type: EMPTY_COURSE_LISTING
});


const getqueryParamsListing = (config, id) => {
    const listingId = new UUID(id);
    const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;
    return {
        id: listingId,
        include: ['images', 'currentStock'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    }
}

export const createUpdateCourse = (params) => async (dispatch, getState, sdk) => {
    dispatch(onCreateUpdateCourse(true));
    const { listingData, updateType, config, tab } = params;
    const { stockUpdateMaybe, createSubscription = false, ...rest } = listingData;
    const currentUser = getState().user.currentUser;
    if (updateType == "publishDraft") {
        await sdk.ownListings.publishDraft({ id: rest.id }, { expand: true }).then((response) => {
            try {
                const { title } = response.data.data.attributes;
                const slug = createSlug(title);
                const url = REACT_APP_MARKETPLACE_ROOT_URL + `/l/${slug}/${response.data.data.id.uuid}`;
                onCreateMultipleNotification({
                    id: currentUser.id.uuid,
                    contentTitle: "Descubre la nueva clase " + title,
                    heading: currentUser?.attributes?.profile?.publicData?.schoolName,
                    web_url: url,
                    type: "LESSON"
                })
            } catch (e) {
                console.log('&&& onCreateMultipleNotification Error &&& => requestPublishListingDraft 707', e);
            }
        })
    }

    const show = updateType == "createDraft" ? sdk.ownListings.createDraft({ ...rest }, { expand: true })
        : sdk.ownListings.update({ ...rest }, { expand: true });

    return show.then(async (response) => {
        const subscriptionCreated = response?.data?.data?.attributes?.publicData?.subscriptionCreated;
        const listingPublished = response?.data?.data?.attributes?.state == "published";

        if (tab && tab == DATE && listingPublished) {
            const { dayAvailability } = response.data.data.attributes.publicData;
            const instructors = getState().SchoolInstructorPage.instructors;
            const sessionId = response.data.data.id.uuid;
            const lessonId = response.data.data.id.uuid;
            const allInstructorsData = (dayAvailability && dayAvailability.length && instructors && instructors.length && sessionId && lessonId) ? getAllInstructorsData(dayAvailability, instructors, sessionId, lessonId) : false;
            allInstructorsData && allInstructorsData.length ? await dispatch(onAddInstructorsInSession(allInstructorsData, sessionId)) : null;
        }

        // stock api changed until the user have not created subscription 
        if (stockUpdateMaybe && stockUpdateMaybe.newTotal) {
            const { oldTotal, newTotal } = stockUpdateMaybe
            await sdk.stock.compareAndSet({
                listingId: response.data.data.id,
                oldTotal,
                newTotal
            }, {
                expand: true
            });
        }

        if (!subscriptionCreated && createSubscription) {
            await onStripeCreateProduct({ listingId: response.data.data.id.uuid, authorId: currentUser.id.uuid });
        }

        return sdk.ownListings.show(getqueryParamsListing(config, response.data.data.id.uuid))
    }).then(res => {
        const ownResponse = makeOwnlistingResponse(res);
        dispatch(queryCourseListingSuccess(ownResponse));
        dispatch(onCreateUpdateCourse(false));
        return res
    }).catch((e) => {
        dispatch(onCreateUpdateCourse(false));
        return storableError((e));
    })
}

export function listingImageUpload(actionPayload, listingImageConfig) {
    return (dispatch, getState, sdk) => {
        const id = actionPayload.id;
        const imageVariantInfo = getImageVariantInfo(listingImageConfig);
        const queryParams = {
            expand: true,
            'fields.image': imageVariantInfo.fieldsImage,
            ...imageVariantInfo.imageVariants,
        };
        dispatch(uploadListingImageRequest(actionPayload));
        return sdk.images
            .upload({ image: actionPayload.file }, queryParams)
            .then(resp => {
                const img = resp.data.data;
                // Uploaded image has an existing id that refers to file
                // The UUID was created as a consequence of this upload call - it's saved to imageId property
                return dispatch(
                    uploadListingImageSuccess({ ...img, id, imageId: img.id, file: actionPayload.file })
                );
            })
            .catch(e => dispatch(uploadListingImageError({ id, error: storableError(e) })));
    };
}


export const querySchoolListing = (ownListingQueryParams) => async (dispatch, getState, sdk) => {
    dispatch(queryCourseListingRequest());
    return sdk.ownListings.show(ownListingQueryParams).then(res => {
        const ownResponse = makeOwnlistingResponse(res);
        dispatch(queryCourseListingSuccess(ownResponse));
        return res
    }).catch(e => {
        dispatch(queryCourseListingError(storableError(e)));
        throw e;
    })
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    const { id } = params;
    dispatch(emptyCourseListingData());

    return Promise.all([dispatch(fetchCurrentUser())])
        .then((response) => {
            const currentUser = getState().user.currentUser;
            const userId = currentUser.id.uuid;
            // const searchFilter = `sort[0]=id:asc&pagination[page]=${1}&pagination[pageSize]=${100}&filters[schoolId][$eq]=${userId}&filters[platformLogin][$eq]=${true}`;
            const searchFilter = schoolInstructorFilterArray(userId);
            dispatch(queryInstructors("", userId, true, searchFilter));
            if (id && id != "1111") {
                const ownListingQueryParams = getqueryParamsListing(config, id)
                dispatch(querySchoolListing(ownListingQueryParams));
            }
        })
};