import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { onGetApiCollecton, onUpdateApiCollecton } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { makeStrapiFilters, STRAPI_SCHOOL_INSTRUCTORS_URL } from '../../config/configStrapiUrl';
import { DELETE } from '../../util/types';



const RESULT_PAGE_SIZE = 15;


// ================ Action types ================ //

export const QUERY_INSTRUCTORS_REQUEST = 'app/SchoolInstructorPage/QUERY_INSTRUCTORS_REQUEST';
export const QUERY_INSTRUCTORS_SUCCESS = 'app/SchoolInstructorPage/QUERY_INSTRUCTORS_SUCCESS'
export const QUERY_INSTRUCTORS_ERROR = 'app/SchoolInstructorPage/QUERY_INSTRUCTORS_ERROR';

export const UPDATE_INSTRUCTORS_REQUEST = 'app/SchoolInstructorPage/UPDATE_INSTRUCTORS_REQUEST';
export const UPDATE_INSTRUCTORS_SUCCESS = 'app/SchoolInstructorPage/UPDATE_INSTRUCTORS_SUCCESS';
export const UPDATE_INSTRUCTORS_ERROR = 'app/SchoolInstructorPage/UPDATE_INSTRUCTORS_ERROR';
// ================ Reducer ================ //

const initialState = {
  instructors: [],
  instructorsLoader: false,
  instructorsError: false,
  pagination: null,
  updateInstructorError: false,
  updateInstructorLoader: false,
};

const newPaginationParams = (pagination) => {
  const { perPage, totalItems } = pagination;
  const newTotal = totalItems - 1;
  const newPagination = {
    ...pagination,
    totalItems: newTotal,
    totalPages: Math.ceil(newTotal / perPage),
  };
  return newPagination;
}

const getNewUpdatedData = (prevState, payload) => {
  try {
    const { data, type } = payload;
    const { instructors, pagination } = prevState;
    const newInstructors = type == DELETE ? instructors.filter((st) => st.id != data.id) : instructors.map((st) => st.id == data.id ? data : st);
    const newPagination = type == DELETE ? newPaginationParams(pagination) : pagination;
    return {
      instructors: newInstructors,
      pagination: newPagination
    }
  } catch (E) {
    console.log(E);
  }

}


export default function SchoolInstructorPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_INSTRUCTORS_REQUEST:
      return { ...state, instructorsLoader: true, pagination: null, instructorsError: false };
    case QUERY_INSTRUCTORS_SUCCESS:
      return { ...state, instructors: payload.data, instructorsLoader: false, pagination: payload.pagination, instructorsError: false }
    case QUERY_INSTRUCTORS_ERROR:
      return { ...state, instructorsLoader: false, pagination: null, instructorsError: true };

    case UPDATE_INSTRUCTORS_REQUEST:
      return { ...state, updateInstructorLoader: payload, updateInstructorError: false };

    case UPDATE_INSTRUCTORS_SUCCESS:
      const newUpatedData = getNewUpdatedData(state, payload);
      return {
        ...state,
        updateInstructorLoader: false,
        updateInstructorError: false,
        ...newUpatedData
      }
    case UPDATE_INSTRUCTORS_ERROR:
      return { ...state, updateInstructorLoader: false, updateInstructorError: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const queryInstructorsRequest = () => ({
  type: QUERY_INSTRUCTORS_REQUEST,
});

export const queryInstructorsSuccess = (data) => ({
  type: QUERY_INSTRUCTORS_SUCCESS,
  payload: data,
})

export const queryInstructorsError = e => ({
  type: QUERY_INSTRUCTORS_ERROR,
  payload: e,
});


export const updateInstructorsRequest = (data) => ({
  type: UPDATE_INSTRUCTORS_REQUEST,
  payload: data,
});

export const updateInstructorsSuccess = (data) => ({
  type: UPDATE_INSTRUCTORS_SUCCESS,
  payload: data,
})

export const updateInstructorsError = e => ({
  type: UPDATE_INSTRUCTORS_ERROR,
  payload: e,
});


// ================ Thunks ================ //

export const queryInstructors = (search, userId, isLoader = false, searchFilters) => async (dispatch, getState, sdk) => {
  !isLoader && dispatch(queryInstructorsRequest())
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const perPage = RESULT_PAGE_SIZE;

  const sessionFilters = [
    { type: "pagination", keyName: "page", keyFilter: "", keyValue: page },
    { type: "pagination", keyName: "pageSize", keyFilter: "", keyValue: perPage },
    { type: "sort", keyName: 0, keyFilter: "", keyValue: "id:asc" }, // desc
    { type: "filters", keyName: "schoolId", keyFilter: "$eq", keyValue: userId },
    { type: "filters", keyName: "isDeleted", keyFilter: "$eq", keyValue: false }
  ];

  const searchFilter = searchFilters ? searchFilters : makeStrapiFilters(sessionFilters);
  const getSessionParams = { API_TYPE: STRAPI_SCHOOL_INSTRUCTORS_URL, data: { searchFilter } };

  return onGetApiCollecton(getSessionParams)
    .then(async (response) => {
      const pagination = {
        perPage: response.meta.pageSize,
        totalItems: response.meta.total,
        totalPages: response.meta.pageCount,
        page: response.meta.page
      }
      dispatch(queryInstructorsSuccess({ ...response, pagination }));
      return response;
    })
    .catch((e) => {
      return dispatch(queryInstructorsError(storableError(e)));
    });
};

// type >>> "UPDATED" || "DELETED" 
export const updateSchoolInstructor = (data, type) => async (dispatch, getState, sdk) => {
  const { id } = data;
  dispatch(updateInstructorsRequest(id))
  const getSessionParams = { API_TYPE: STRAPI_SCHOOL_INSTRUCTORS_URL, data };
  // update the record in strapi school instructor table strapi
  return onUpdateApiCollecton(getSessionParams)
    .then(async (response) => {
      dispatch(updateInstructorsSuccess({ type, data: response.data }));
      return response;
    })
    .catch((e) => {
      return dispatch(updateInstructorsError(storableError(e)));
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(res => {
      const currentUser = getState().user.currentUser;
      const userId = currentUser.id.uuid;
      if (userId) {
        dispatch(queryInstructors(search, userId));
      }
    })
    .catch(e => {
      throw e;
    })
};

