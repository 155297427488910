import moment from 'moment';
import pick from 'lodash/pick';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getStrapiFreeLessonsData, onGetSessionTransaction, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { COURSE_LESSON_CATEGORY, PACK_OF_LESSON_CATEGORY, SESSION_TYPE } from '../../util/types';
import { getTimeSlots } from '../ProfilePage/ProfilePage.duck';

const { UUID } = sdkTypes;
const secretKey = 'yourSecretKey'; // Replace with your secret key
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';

export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const FETCH_FREE_LESSON_REQUEST = 'app/ListingPage/FETCH_FREE_LESSON_REQUEST';
export const FETCH_FREE_LESSON_SUCCESS = 'app/ListingPage/FETCH_FREE_LESSON_SUCCESS';
export const FETCH_FREE_LESSON_ERROR = 'app/ListingPage/FETCH_FREE_LESSON_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const SESSION_LISTING_FETCH_SUCCESS = 'app/ListingPage/SESSION_LISTING_FETCH_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  isdecrypted: null,
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  fetchFreeLessonError: null,
  fetchFreeLessonInProgreess: false,
  inquiryModalOpenForListingId: null,
  sessionListings: [],
  timeSlots: [],
  freeLessons: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };

    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };
    case FETCH_FREE_LESSON_REQUEST:
      return { ...state, fetchFreeLessonInProgreess: true, fetchFreeLessonError: null };
    case FETCH_FREE_LESSON_SUCCESS:
      return { ...state, fetchFreeLessonInProgreess: false, freeLessons: payload };
    case FETCH_FREE_LESSON_ERROR:
      return { ...state, fetchFreeLessonInProgreess: false, fetchFreeLessonError: payload };
    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };
    case SESSION_LISTING_FETCH_SUCCESS:
      return {
        ...state,
        sessionListings: payload.sessionListings,
        timeSlots: payload.allTimeSlots,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: reviews
});

export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});
export const fetchFreeLessonRequest = () => ({ type: FETCH_FREE_LESSON_REQUEST });
export const fetchFreeLessonSuccess = freeLessons => ({
  type: FETCH_FREE_LESSON_SUCCESS,
  payload: freeLessons,
});
export const fetchFreeLessonError = error => ({
  type: FETCH_FREE_LESSON_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const sessionListingSuccess = data => ({
  type: SESSION_LISTING_FETCH_SUCCESS,
  payload: data,
});

// ================ Thunks ================ //

const getAllDaysTimeSlotsIs = (startDateUnix, endDateUnix, daysOfWeek, listingData,stockQuantity) => {
  const currentDate = moment(startDateUnix * 1000);
  const endDate = moment(endDateUnix * 1000);
  const matchingDays = [];
  const alldays = daysOfWeek.map((st) => st.day);

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    const day = currentDate.format('dddd');

    if (alldays.includes(day)) {
      const currentDateValue = currentDate.format("YYYY-MM-DD");
      const getStartTimeIndex = daysOfWeek.findIndex((item) => item.day == day);

      if (getStartTimeIndex >= 0) {
        const { deletedDays, exceptionDays } = listingData;

        const isDeleted = deletedDays && deletedDays.length ? deletedDays.findIndex((ddd) => moment(ddd * 1000).format('YYYY-MM-DD') == currentDateValue) : -1;
        const alreadyExceptionIndex = exceptionDays && exceptionDays.length ? exceptionDays.findIndex((item) => item.currentDateIs && moment((+item.currentDateIs) * 1000).format("YYYY-MM-DD") == currentDateValue) : -1;

        if (alreadyExceptionIndex >= 0 && isDeleted == -1) {
          matchingDays.push({
            attributes: {
              type: "time-slot/day",
              start: moment(currentDateValue + " " + daysOfWeek[getStartTimeIndex].startTime).toDate(),
              end: moment(currentDateValue + " " + daysOfWeek[getStartTimeIndex].endTime).toDate(),
              ...daysOfWeek[getStartTimeIndex],
              ...listingData,
              ...exceptionDays[alreadyExceptionIndex],
              seats: exceptionDays[alreadyExceptionIndex].seats,
              timeSlotSeats: exceptionDays[alreadyExceptionIndex].seats
            },
            id: new UUID("652faf11-1480-4d0a-8f9d-af8d9df7f2b2"),
            type: "timeSlot"
          })
        } else if (isDeleted == -1) {
          matchingDays.push({
            attributes: {
              type: "time-slot/day",
              start: moment(currentDateValue + " " + daysOfWeek[getStartTimeIndex].startTime).toDate(),
              end: moment(currentDateValue + " " + daysOfWeek[getStartTimeIndex].endTime).toDate(),
              ...daysOfWeek[getStartTimeIndex],
              ...listingData,
              timeSlotSeats: (stockQuantity || 0),
              seats: daysOfWeek[getStartTimeIndex].seats
            },
            id: new UUID("652faf11-1480-4d0a-8f9d-af8d9df7f2b2"),
            type: "timeSlot"
          })
        }
      }

    }
    currentDate.add(1, 'day');
  }

  return matchingDays
};

export const sessionListings = (sanitizeConfig, listing,stockQuantity) => async (dispatch, getState, sdk) => {
  const { id, attributes } = listing;
  const { publicData, title } = attributes;
  const { lessonCategory = false, lessonPacks = [] } = publicData;

  let allTimeSlots = [];
  let sessionListingsArray = [];

  if (lessonCategory == COURSE_LESSON_CATEGORY) {
    const { startDate, endDate, dayAvailability } = publicData; 
    const onGetCourseTimeSlot = getAllDaysTimeSlotsIs(moment(startDate).unix() > moment().unix() ? moment(startDate).unix() : moment().unix(), moment(endDate).unix(), dayAvailability, { ...publicData, title },stockQuantity);
    const getTimeSlotDays = onGetCourseTimeSlot.filter((s,i)=> moment(s.attributes.start).unix() > moment().unix()).filter((s,i)=> i<3).map((st)=> moment(st.attributes.start).format("DD-MM-YYYY"));
    
    const searchDateFilter = getTimeSlotDays.length && getTimeSlotDays.map(st=> `filters[date][$gt]=${st}`).join("&");
    const searchFilter = getTimeSlotDays.length && `pagination[page]=${1}&pagination[pageSize]=${100}&filters[listingId][$eq]=${id.uuid}&`+searchDateFilter;
    const getSessionData = searchFilter && await onGetSessionTransaction({ searchFilter });
    
    const nowGetProperResponse = getTimeSlotDays.length && getTimeSlotDays.map((st)=> ({day:st ,length:getSessionData.data.filter(item=> item.attributes.date==st).length}))

    const allTimeSlots = onGetCourseTimeSlot.filter((s,i)=> moment(s.attributes.start).unix() > moment().unix()).map((st)=> {
      const findIndexSlot = nowGetProperResponse.findIndex((ite)=> ite.day ==moment(st.attributes.start).format("DD-MM-YYYY")); 
      return findIndexSlot>=0 ? {...st , attributes: {...st.attributes ,timeSlotSeats:st.attributes.seats - nowGetProperResponse[findIndexSlot].length  } } : st;
    } );
    
    dispatch(sessionListingSuccess({ sessionListings: [], allTimeSlots: allTimeSlots }))
  }

  // loop occurs
  else if (lessonCategory == PACK_OF_LESSON_CATEGORY) {
    for (let i = 0; i < lessonPacks.length; i++) {
      const element = lessonPacks[i];
      await sdk.listings
        .query({
          pub_parentId: element.id,
          expand: true,
          pub_sessionDatePassed: moment().unix() + ',',
        })
        .then(async response => {
          if (response?.data?.data && response.data.data.length) {
            const filterListing = response.data.data.filter(
              st => st.attributes.publicData.learningType == SESSION_TYPE
            );
            if (filterListing && filterListing.length) {
              const onGetTimeSlot = await getTimeSlots(filterListing, sdk);
              allTimeSlots = [...allTimeSlots, ...onGetTimeSlot];
            }
          }

          dispatch(addMarketplaceEntities(response, sanitizeConfig));
          sessionListingsArray = [...sessionListingsArray, ...response.data.data];
        })
        .catch(e => {
          console.error(e, '&&& &&& => e');
        });
    }
    dispatch(
      sessionListingSuccess({
        sessionListings: sessionListingsArray.map(l => l.id),
        allTimeSlots: allTimeSlots,
      })
    );
  }
  // single call
  else {
    sdk.listings
      .query({ pub_parentId: id.uuid, expand: true, pub_sessionDatePassed: moment().unix() + ',' })
      .then(async response => {
        if (response?.data?.data && response.data.data.length) {
          const filterListing = response.data.data.filter(
            st => st.attributes.publicData.learningType == SESSION_TYPE
          );
          allTimeSlots =
            filterListing && filterListing.length ? await getTimeSlots(filterListing, sdk) : [];
        }
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
        dispatch(
          sessionListingSuccess({
            sessionListings: response.data.data.map(l => l.id),
            allTimeSlots: allTimeSlots,
          })
        );
      })
      .catch(e => {
        console.error(e, '&&& &&& => e');
      });
  }
};

export const showListing = (listingId, config, isOwn = false) => async (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(async data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      const stockData = data.data?.included && data.data?.included.length && data.data?.included.filter((st)=> st.type=="stock");
      const stockQuantity = stockData && stockData.length && stockData[0].attributes?.quantity;
      await dispatch(sessionListings(sanitizeConfig, data.data.data,stockQuantity));
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

const fetchFreeLessonData = params => dispatch => {
  try {

    dispatch(fetchFreeLessonRequest());

    return getStrapiFreeLessonsData(params)
      .then(res => {
        const freeLessonData = res.data && res.data.length
          ? { ...res.data[0].attributes, id: res.data[0].id }
          : { isLessonUsed: true };

        dispatch(fetchFreeLessonSuccess(freeLessonData))
        return res;
      })
      .catch(e => {
        dispatch(fetchFreeLessonError(storableError(e)));
        log.error(e, 'fetching-free-lesson-failed');
      });
  } catch (e) {
    log.error(e, 'fetching-free-lesson-failed');
    dispatch(fetchFreeLessonError(storableError(e)));
  }
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);
  // console.log('search1', parse(search));

  const searchValues = search && search.length ? parse(search) : {};

  if (searchValues && searchValues.utm) {
    dispatch(fetchFreeLessonData({ utm: searchValues.utm }));
  }

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));
  dispatch(setInitialValues({ freeLessons: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }
    return response;
  });
};
