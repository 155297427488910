import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { LESSON_TYPE, PACK_OF_LESSON_CATEGORY, SESSION_TYPE, COURSE_LESSON_CATEGORY } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import moment from 'moment';
import { onGetSessionInstructors } from '../../util/api';

const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 100;


export const INSTRUCTOR_PLANNING_PAGE_REQUEST = 'app/InstructorPanningPage/INSTRUCTOR_PLANNING_PAGE_REQUEST';

export const QUERY_LISTINGS_REQUEST = 'app/InstructorPanningPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/InstructorPanningPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/InstructorPanningPage/QUERY_LISTINGS_ERROR';


const initialState = {
    instructorPlanningPageLoading: false,
    queryListingsRequest: false,
    lessonListings: [],
    combineCourseSessionListings: [],
    queryListingsError: null,
};

export default function instructorPanningPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case INSTRUCTOR_PLANNING_PAGE_REQUEST:
            return {
                ...state,
                instructorPlanningPageLoading: payload
            }
        case QUERY_LISTINGS_REQUEST:
            return {
                ...state,
                queryListingsRequest: true,
                queryListingsError: null,
            };
        case QUERY_LISTINGS_SUCCESS:
            const { combineCourseSessionListings, lessonListings } = payload;
            return { ...state, combineCourseSessionListings, lessonListings };
        case QUERY_LISTINGS_ERROR:
            return { ...state, userListingRefs: [], queryListingsError: payload };
        default:
            return state;
    }
}

export const instructorPlanningPageLoading = (data) => ({
    type: INSTRUCTOR_PLANNING_PAGE_REQUEST,
    payload: data
})

export const queryListingsRequest = userId => ({
    type: QUERY_LISTINGS_REQUEST,
    payload: { userId },
});

export const queryListingsSuccess = (data) => ({
    type: QUERY_LISTINGS_SUCCESS,
    payload: data,
});

export const queryListingsError = e => ({
    type: QUERY_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const queryUserListings = (userId, lessonPagination) => (dispatch, getState, sdk) => {
    try {
        dispatch(queryListingsRequest(userId));
        const { page, perPage } = lessonPagination;
        const searchFilter = `sort[0]=id:asc&pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[instructorId][$eq]=${userId}`
        return onGetSessionInstructors({ searchFilter })
            .then(async (response) => {
                const pagination = {
                    perPage: response.pagination.pageSize,
                    totalItems: response.pagination.total,
                    totalPages: response.pagination.pageCount,
                    page: response.pagination.page
                }
                let combineCourseSessionListings = [];
                let lessonListings = [];

                const allSessions = response.data.map((st) => st.attributes.sessionId);
                const allLessons = response.data.map((st) => st.attributes.lessonId);

                if (allLessons && allLessons.length) {
                    const lessonListingsSharetribe = await sdk.listings.query({
                        pub_learningType: LESSON_TYPE,
                        ids: allLessons.join(","),

                    });
                    lessonListings = lessonListingsSharetribe?.data?.data ? lessonListingsSharetribe?.data?.data : [];
                }


                const sessionListingsSharetribe = allSessions && allSessions.length ? await sdk.listings.query({
                    pub_learningType: SESSION_TYPE,
                    ids: allSessions.join(","),
                    // pub_sessionDatePassed: (moment().unix() + ","),
                }) : []
                const courseListingData = lessonListings.filter(item => item?.attributes?.publicData?.lessonCategory == COURSE_LESSON_CATEGORY);
                const combineCourseSession = (sessionListingsSharetribe?.data?.data && sessionListingsSharetribe?.data?.data?.length) ? [...courseListingData, ...sessionListingsSharetribe.data.data] : courseListingData;
                combineCourseSessionListings = combineCourseSession.length ? combineCourseSession.map((item) => {
                    if (item?.attributes?.publicData?.lessonCategory === "COURSE") {
                        return { ...item, listingId: item.id.uuid, searchId: item.id.uuid }
                    }
                    else {
                        return { ...item, listingId: item?.attributes?.publicData?.parentId, searchId: item.id.uuid }
                    }
                })
                    .filter((st) => st.listingId)
                    : [];


                dispatch(queryListingsSuccess({ combineCourseSessionListings, lessonListings, pagination }));

            }).catch(e => dispatch(queryListingsError(storableError(e))));

    }
    catch (e) {
        dispatch(queryListingsError(storableError(e)))
        return
    }
};


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    try {
        const queryParams = parse(search);
        const page = queryParams.page || 1;

        const { checkedItemsIds, calenderView } = queryParams || {};
        if (checkedItemsIds || calenderView) {

        } else {
            dispatch(instructorPlanningPageLoading(true));
        }


        const lessonPagination = {
            page,
            perPage: RESULT_PAGE_SIZE,
        }

        return Promise.all([dispatch(fetchCurrentUser())])
            .then(async (res) => {
                const currentUser = getState().user?.currentUser;
                const userId = currentUser?.id?.uuid;
                if (userId) {
                    await dispatch(queryUserListings(userId, lessonPagination));
                }
                dispatch(instructorPlanningPageLoading(false));
            }).catch((e) => {
                dispatch(instructorPlanningPageLoading(false));
                throw e;
            })
    } catch (e) {
        dispatch(instructorPlanningPageLoading(false));
        throw e;
    }
};