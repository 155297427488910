
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';


// ================ Action types ================ //

export const SHOW_SUBSCRIPTIONS_REQUEST = 'app/StudentSubscriptionPage/SHOW_SUBSCRIPTIONS_REQUEST';
export const SHOW_SUBSCRIPTIONS_SUCCESS = 'app/StudentSubscriptionPage/SHOW_SUBSCRIPTIONS_SUCCESS';
export const SHOW_SUBSCRIPTIONS_ERROR = 'app/StudentSubscriptionPage/SHOW_SUBSCRIPTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  showStudentSubscriptionsError: null,
  studentSubscriptions: []
};

export default function stutentSubscriptionsPageReducer(state = initialState, action = {}){
  const { type, payload } = action;
  switch (type) {

    case SHOW_SUBSCRIPTIONS_REQUEST:
      return { ...state, id: payload.id, showStudentSubscriptionsError: null };
  case SHOW_SUBSCRIPTIONS_SUCCESS:
      return { ...state, studentSubscriptions: payload };

    case SHOW_SUBSCRIPTIONS_ERROR:
        return { ...state, showStudentSubscriptionsError: payload };

    default:
      return state;
  }
};

// ================ Action creators ================ //

export const showSubscriptionsRequest = data => ({
  type: SHOW_SUBSCRIPTIONS_REQUEST,
  payload: { data },
});

export const showSubscriptionsSuccess = data => ({
type: SHOW_SUBSCRIPTIONS_SUCCESS,
  error: false,
  payload: data,
});

export const showStudentSubscriptionsError = e => ({
  type: SHOW_SUBSCRIPTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showSubscriptions = () => (dispatch, getState, sdk) => {
  dispatch(showSubscriptionsRequest());
  dispatch(showSubscriptionsSuccess([
    {
      "id": "su_1O26RD2eZvKYlo2CtVdsOiXb",
      "object": "subscription",
      "application": null,
      "application_fee_percent": null,
      "automatic_tax": {
        "enabled": false
      },
      "billing_cycle_anchor": 1697522851,
      "billing_thresholds": null,
      "cancel_at": null,
      "cancel_at_period_end": false,
      "canceled_at": null,
      "cancellation_details": {
        "comment": null,
        "feedback": null,
        "reason": null
      },
      "collection_method": "charge_automatically",
      "created": 1697522851,
      "currency": "usd",
      "current_period_end": 1700201251,
      "current_period_start": 1697522851,
      "customer": "cus_OplzA6R5KOTVJP",
      "days_until_due": null,
      "default_payment_method": "pm_1O26RC2eZvKYlo2CyaBzRKEi",
      "default_source": null,
      "default_tax_rates": [],
      "description": null,
      "discount": null,
      "ended_at": null,
      "items": {
        "object": "list",
        "data": [
          {
            "id": "si_Oplzy31r147HWV",
            "object": "subscription_item",
            "billing_thresholds": null,
            "created": 1697522851,
            "metadata": {},
            "price": {
              "id": "price_1O26Ox2eZvKYlo2CG7JDMTQ5",
              "object": "price",
              "active": true,
              "billing_scheme": "per_unit",
              "created": 1697522711,
              "currency": "usd",
              "custom_unit_amount": null,
              "livemode": false,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_OplwnrK4oek5VD",
              "recurring": {
                "aggregate_usage": null,
                "interval": "month",
                "interval_count": 1,
                "usage_type": "licensed"
              },
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "recurring",
              "unit_amount": 1200,
              "unit_amount_decimal": "1200"
            },
            "quantity": 1,
            "subscription": "sub_1O26RD2eZvKYlo2C7U9MOfrJ",
            "tax_rates": []
          }
        ],
        "has_more": false,
        "url": "/v1/subscription_items?subscription=sub_1O26RD2eZvKYlo2C7U9MOfrJ"
      },
      "latest_invoice": "in_1O26RD2eZvKYlo2CSIf3Sm0z",
      "livemode": false,
      "metadata": {},
      "next_pending_invoice_item_invoice": null,
      "on_behalf_of": null,
      "pause_collection": null,
      "payment_settings": {
        "payment_method_options": null,
        "payment_method_types": null,
        "save_default_payment_method": "off"
      },
      "pending_invoice_item_interval": null,
      "pending_setup_intent": null,
      "pending_update": null,
      "schedule": null,
      "start_date": 1697522851,
      "status": "active",
      "test_clock": null,
      "transfer_data": null,
      "trial_end": null,
      "trial_settings": {
        "end_behavior": {
          "missing_payment_method": "create_invoice"
        }
      },
      "trial_start": null
    },
  ]));
  dispatch(showStudentSubscriptionsError());
};


export const loadData = (params, search, config) => (dispatch,getState,sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
  .then(response => {
    dispatch(showSubscriptions())
    return response;
  })
  .catch(e => {
    throw e;
  });
};
