/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { ORDER_INBOX_PAGE_LESSON, ORDER_INBOX_PAGE_SCHOOL, ORDER_INBOX_PAGE_STUDENT, SCHOOL_TYPE, USER_ROLE_INSTRUCTOR, propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';


const mobileMenu = (userType, currentPageClass) => {
  if (userType && userType==SCHOOL_TYPE) {
    return [
      {
        key: "SchoolPlanning",
        className: classNames(css.navigationLink, currentPageClass('SchoolPlanning')),
        nameLink:"SchoolPlanning",
        name: <FormattedMessage id="TopbarDesktop.allLesson" />
        // <FormattedMessage id="TopbarDesktop.yourListingsLink" />
      },
      {
        key: "ManageListingsPage",
        className: classNames(css.navigationLink, currentPageClass('ManageListingsPage')),
        nameLink: "ManageListingsPage",
        name: <FormattedMessage id="UserNav.allLessonPage" />
        // <FormattedMessage id="TopbarDesktop.yourListingsLink" />
      },
      {
        key:"InboxPage",
        className:classNames(css.navigationLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_SCHOOL , type:ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "SchoolInvitePage",
        className: classNames(css.navigationLink, currentPageClass('SchoolInvitePage')),
        nameLink: "SchoolInvitePage",
        name:  <FormattedMessage id="TopbarDesktop.InviteUsersPage" />
      },
      {
        key: "SchoolInstructorPage",
        className: classNames(css.navigationLink, currentPageClass('SchoolInstructorPage')),
        nameLink: "SchoolInstructorPage",
        name: <FormattedMessage id="TopbarDesktop.SchoolInstructorPage" />,
      },
      {
        key: "ProfileSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      },
      {
        key: "AccountSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },
    ]
  }
  else if(userType && userType==USER_ROLE_INSTRUCTOR){
    return [
      {
        key: "InstructorPanningPage",
        className: classNames(css.navigationLink, currentPageClass('InstructorPanningPage')),
        nameLink: "InstructorPanningPage",
        name: <FormattedMessage id="TopbarDesktop.InstructorPanningPage" />,
        params:{}
      },
      {
        key:"InboxPage",
        className:classNames(css.navigationLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_STUDENT , type:ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "BookingPage",
        className: classNames(css.navigationLink, currentPageClass('BookingPage')),
        nameLink: "BookingPage",
        name: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        params: { tab: "pending" }
      },
      {
        key: "StudentSchool",
        className: classNames(css.navigationLink, currentPageClass('DashBoardPage')),
        nameLink: "StudentSchool",
        name: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />
      }, {
        key: "ProfileSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      }, {
        key: "AccountSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },
    ]
  }

  else {
    return [
      {
        key:"InboxPage",
        className:classNames(css.navigationLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_STUDENT , type:ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "BookingPage",
        className: classNames(css.navigationLink, currentPageClass('BookingPage')),
        nameLink: "BookingPage",
        name: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        params: { tab: "pending" }
      },
      {
        key: "StudentSchool",
        className: classNames(css.navigationLink, currentPageClass('DashBoardPage')),
        nameLink: "StudentSchool",
        name: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />
      }, {
        key: "ProfileSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      }, {
        key: "AccountSettingsPage",
        className: classNames(css.navigationLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },
    ]
  }
}


const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    chatNotification
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userType = currentUser && currentUser?.attributes?.profile?.publicData?.userListingType;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge = chatNotification ? <div className={css.notificationDot} /> : null
    // notificationCount > 0 ? (
    //   <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    // ) : null;

  const displayName = user.attributes.profile.firstName;
  
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('ChatPageInbox'))}
          name="ChatPageInbox"
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        

        {mobileMenu(userType, currentPageClass).map((st,index) => <NamedLink
          className={st.className}
          name={st.nameLink}
          params={st.params || {}}
          key={"mobelemenuTab" + index}
        >
          {st.name}
        </NamedLink>
        )}

        <div className={css.spacer} />
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
