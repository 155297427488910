import moment from 'moment';
import { updatedEntities, denormalisedEntities, getAllInstructorsData, delNewAddInstructors } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { getCurrentTimeMultipleOfFive, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import { PERIODICITY_TYPE_MULTIPLE, PERIODICITY_TYPE_SINGLE, SESSION_TYPE } from '../../util/types';
import { getProperResponseOfListing } from '../ManageListingsPage/ManageListingsPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { onCreateApiCollecton, onCreateMultipleNotification, onCreateSessionInstructors, onGetAdditionalQuestions } from '../../util/api';
import { queryInstructors } from '../SchoolInstructorPage/SchoolInstructorPage.duck';
import { joinSessionPeriodValidTypes } from '../../config/configListing';
import { schoolInstructorFilterArray, STRAPI_SESSION_URL } from '../../config/configStrapiUrl';

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const { UUID, Money } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SchoolDashBoardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SchoolDashBoardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SchoolDashBoardPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/SchoolDashBoardPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/SchoolDashBoardPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/SchoolDashBoardPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/SchoolDashBoardPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/SchoolDashBoardPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/SchoolDashBoardPage/CLOSE_LISTING_ERROR';

export const CREATE_LISTING_REQUEST = 'app/SchoolDashBoardPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/SchoolDashBoardPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/SchoolDashBoardPage/CREATE_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/SchoolDashBoardPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/SchoolDashBoardPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/SchoolDashBoardPage/UPDATE_LISTING_ERROR';

export const EXCEPTION_LISTING_REQUEST = 'app/SchoolDashBoardPage/EXCEPTION_LISTING_REQUEST';
export const EXCEPTION_LISTING_SUCCESS = 'app/SchoolDashBoardPage/EXCEPTION_LISTING_SUCCESS';
export const EXCEPTION_LISTING_ERROR = 'app/SchoolDashBoardPage/EXCEPTION_LISTING_ERROR';

export const FETCH_TIME_SLOTS = 'app/SchoolDashBoardPage/FETCH_TIME_SLOTS'

export const ADD_OWN_ENTITIES = 'app/SchoolDashBoardPage/ADD_OWN_ENTITIES';

export const ADDITIONAL_QUESTION_LOADER = 'app/SchoolDashBoardPage/ADDITIONAL_QUESTION_LOADER';
export const ADDITIONAL_QUESTION = 'app/SchoolDashBoardPage/ADDITIONAL_QUESTION';
export const ADDITIONAL_QUESTION_ERROR = 'app/SchoolDashBoardPage/ADDITIONAL_QUESTION_ERROR';


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: true,
  queryListingsError: null,
  listings: [],
  pageListing: {},
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  createListingRequest: false,
  createListingError: false,
  updateListingRequest: false,
  updateListingError: false,
  exceptionListingRequest: false,
  exceptionListingError: false,
  fetchTimeSlots: false,
  additionalQuestionLoader: false,
  additionalQuestion: null,
  additionalQuestionError: false,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};


const SchoolDashBoardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        listings: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: payload.data,
        pageListing: payload.pageListing,
        pagination: payload.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        createListingRequest: true,
        createListingError: null,
      };
    case CREATE_LISTING_SUCCESS:
      const newPageResultIds = [...state.listings, payload.data.data];
      const newPagination = state.pagination ? { ...state.pagination, totalItems: state.pagination.totalItems + 1 } : {
        "totalItems": 1,
        "totalPages": 1,
        "page": 1,
        "paginationLimit": 1,
        "perPage": RESULT_PAGE_SIZE
      };
      return {
        ...state,
        createListingError: null,
        createListingRequest: false,
        listings: newPageResultIds,
        pagination: newPagination
      };

    case CREATE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        createListingRequest: false,
        createListingError: true,
      };
    }
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingRequest: true,
        updateListingError: null,
      };
    case UPDATE_LISTING_SUCCESS:
      const UpdatedPageResultIds = state.listings.map((st) => st.id.uuid == payload.data.data.id.uuid ? payload.data.data : st);
      return {
        ...state,
        updateListingRequest: false,
        updateListingError: null,
        listings: UpdatedPageResultIds
      };

    case UPDATE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateListingRequest: false,
        updateListingError: true,
      };
    }
    case EXCEPTION_LISTING_REQUEST:
      return {
        ...state,
        exceptionListingRequest: payload,
        exceptionListingError: null,
      };
    case EXCEPTION_LISTING_SUCCESS:
      return {
        ...state,
        exceptionListingRequest: false,
        exceptionListingError: false,
      };
    case EXCEPTION_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        exceptionListingRequest: false,
        exceptionListingError: payload,
      };
    }
    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case FETCH_TIME_SLOTS: {
      return {
        ...state,
        fetchTimeSlots: payload
      }
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case ADDITIONAL_QUESTION_LOADER: {
      return {
        ...state,
        additionalQuestion: null,
        additionalQuestionLoader: true,
        additionalQuestionError: false,
      }
    }

    case ADDITIONAL_QUESTION: {
      return {
        ...state,
        additionalQuestion: payload,
        additionalQuestionLoader: false,
        additionalQuestionError: false,
      }
    }

    case ADDITIONAL_QUESTION_ERROR: {
      return {
        ...state,
        additionalQuestion: null,
        additionalQuestionLoader: false,
        additionalQuestionError: true,
      }
    }

    default:
      return state;
  }
};

export default SchoolDashBoardPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.SchoolDashBoardPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

const getSessionPassedDate = (endDate, dayAvailability) => {
  if (dayAvailability && dayAvailability.length && endDate) {
    const dayValue = moment(endDate).locale('en').format("dddd");
    const findEndTimeIndex = dayValue ? dayAvailability.findIndex(st => st.day == dayValue) : -1;
    return (findEndTimeIndex >= 0 && dayAvailability[findEndTimeIndex].endTime) ? moment(endDate + " " + dayAvailability[findEndTimeIndex].endTime).toDate() : moment(endDate).toDate();
  } else if (endDate) {
    return moment(endDate).toDate();
  } else {
    return getCurrentTimeMultipleOfFive();
  }
}

const getSessionStartDate = (startDate, dayAvailability) => {
  if (dayAvailability && dayAvailability.length && startDate) {
    const dayValue = moment(startDate).locale('en').format("dddd");
    const findEndTimeIndex = dayValue ? dayAvailability.findIndex(st => st.day == dayValue) : -1;
    return (findEndTimeIndex >= 0 && dayAvailability[findEndTimeIndex].startTime) ? moment(startDate + " " + dayAvailability[findEndTimeIndex].startTime).toDate() : moment(startDate).toDate();
  } else if (startDate) {
    return moment(startDate).toDate();
  } else {
    return getCurrentTimeMultipleOfFive();
  }
}

// function which gives the first date b/w start Date and end Date
const getFirstDate = (startDate, endDate, dayAvailability) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const getDayIndex = (day) => {
    switch (day) {
      case "sun": return 0;
      case "mon": return 1;
      case "tue": return 2;
      case "wed": return 3;
      case "thu": return 4;
      case "fri": return 5;
      case "sat": return 6;
      default: return -1;
    }
  };

  const targetDayIndexes = dayAvailability.map(day => getDayIndex(day.dayOfWeek));

  for (let date = start; date <= end; date.add(1, 'days')) {
    if (targetDayIndexes.includes(date.day())) {
      return date.format('YYYY-MM-DD');
    }
  }

  return null;
};

const getSessionTimes = (dayAvailability, sessionDate) => {
  const particularDaySession = Array.isArray(dayAvailability) && dayAvailability.filter((st) => st.day == moment(sessionDate).format("dddd"));
  const { endTime = "", startTime = "", seats = 0 } = Array.isArray(particularDaySession) && particularDaySession.length && typeof particularDaySession[0] == "object" ? particularDaySession[0] : {};

  return {
    sessionStartTimeUnix: moment(`${sessionDate} ${startTime}`).unix(),
    sessionEndTimeUnix: moment(`${sessionDate} ${endTime}`).unix(),
    sessionJoinQty: (+seats)
  }
};

const checkCancelType = (studentsJoinSession, joinSessionPeriodType, minCancelQty) => {
  return minCancelQty && (+minCancelQty) > 0 && joinSessionPeriodValidTypes.includes(joinSessionPeriodType) && studentsJoinSession && (+studentsJoinSession) > 0 ? true : false;
}


// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const createListingRequest = listingId => ({
  type: CREATE_LISTING_REQUEST,
});

export const createListingSuccess = response => ({
  type: CREATE_LISTING_SUCCESS,
  payload: response,
});

export const createListingError = e => ({
  type: CREATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const updateListingRequest = listingId => ({
  type: UPDATE_LISTING_REQUEST,
});

export const updateListingSuccess = response => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: response,
});

export const updateListingError = e => ({
  type: UPDATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const exceptionListingRequest = listingId => ({
  type: EXCEPTION_LISTING_REQUEST,
  payload: listingId || true
});

export const exceptionListingSuccess = response => ({
  type: EXCEPTION_LISTING_SUCCESS,
  payload: response,
});

export const exceptionListingError = e => ({
  type: EXCEPTION_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const timeSlotsFetch = (data) => ({
  type: FETCH_TIME_SLOTS,
  payload: data
})

export const additionalQuestionLoader = (data) => ({
  type: ADDITIONAL_QUESTION_LOADER,
})

export const additionalQuestion = (data) => ({
  type: ADDITIONAL_QUESTION,
  payload: data
})

export const additionalQuestionError = (data) => ({
  type: ADDITIONAL_QUESTION_ERROR,
})


export const onAddInstructorsInSession = (data, sessionId) => (dispatch, getState, sdk) => {
  return onCreateSessionInstructors({ data, sessionId })
    .then((response) => {
      return response
    })
    .catch(e => {
      return e;
    })
}

// Throwing error for new (loadData may need that info)
export const queryOwnListings = (ownListingQueryParams, queryListingParams, config) => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryListingParams));
  let ownListing;
  return sdk.listings.show(ownListingQueryParams).then(res => {
    ownListing = { ...res.data.data, images: (res.data.included.filter((st) => st.type == "image") || []), author: res.data.included.find((st) => st.type == "user") }
    return sdk.listings.query(queryListingParams)
  }).then((response) => {
    const queryListing = getProperResponseOfListing(response, config)
    dispatch(queryListingsSuccess({ data: queryListing && queryListing.length ? queryListing : [], meta: response.data.meta, pageListing: ownListing }));
    return response;
  }).catch(e => {
    console.log(e, "e");
    dispatch(queryListingsError(storableError(e)));
    throw e;
  })
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};


// first session of strapi 
const onGetStrapiFirstSession = (listing, currentUser) => (dispatch, getState, sdk) => {
  try {
    const { id, attributes } = listing;
    const {
      periodicity,
      startDate,
      endDate,
      dayAvailability,
      parentId,
      studentsJoinSession,
      joinSessionPeriodType,
      minCancelQty,
    } = attributes.publicData;
    const sessionDate = periodicity == "single" ? startDate : getFirstDate(startDate, endDate, dayAvailability);
    const { sessionStartTimeUnix, sessionEndTimeUnix, sessionJoinQty } = getSessionTimes(dayAvailability, sessionDate);
    // moment().add((+studentsJoinSession), joinSessionPeriodType).unix();
    const isCancelType = checkCancelType(studentsJoinSession, joinSessionPeriodType, minCancelQty);

    // check schema in configStapiUrl.js file with name STRAPI_SESSION_URL
    const data = {
      userId: currentUser.id.uuid,
      sessionId: id.uuid,
      lessonId: parentId,
      periodicity,
      sessionDate,
      sessionStartTimeUnix,
      sessionEndTimeUnix,
      sessionType: isCancelType ? "cancel" : "session",
      cancelMinQty: isCancelType ? (+minCancelQty) : 0,
      cancelTime: isCancelType ? moment(sessionStartTimeUnix * 1000).subtract((+studentsJoinSession), joinSessionPeriodType).unix() : 0,
      cancelStatus: isCancelType ? "pending" : "done",
      addNewSessionStatus: periodicity == "single" ? "done" : "pending",
      totalUserJoined: 0,
      sessionJoinQty,
      additional: {
        sessionName: attributes.title,// string
      },
      status: "open"// status (open,delete)
    };
    console.log(data, '&&& &&& => data');

    return onCreateApiCollecton({ API_TYPE: STRAPI_SESSION_URL, data })
      .then((response) => {
        console.log(response, '&&& onGetStrapiFirstSession >> onCreateApiCollecton &&& => e');
        return response;
      })
      .catch(e => {
        console.log(e, '&&& onGetStrapiFirstSession >> onCreateApiCollecton &&& => e');
        return e;
      })
  }
  catch (e) {
    console.log(e, '&&& onGetStrapiFirstSession &&& => e');
    return e;
  }
}

export const CreateListing = (params) => (dispatch, getState, sdk) => {
  try {
    dispatch(createListingRequest());
    // const pageListing = getState().SchoolDashBoardPage.pageListing;
    const currentUser = getState().user.currentUser;
    let createResponse;

    return sdk.ownListings
      .create({ ...params }, { expand: true })
      .then(async (response) => {
        const promisify = [];

        const daySingleSeats = params.publicData.dayAvailability && params.publicData.dayAvailability.length ? (+params.publicData.dayAvailability[0].seats) : false;
        const startDate = getSessionStartDate(params.publicData.startDate, params.publicData.dayAvailability);
        const endDate = getSessionPassedDate(params.publicData.endDate, params.publicData.dayAvailability);

        // add a new session in strapi 
        promisify.push(dispatch(onGetStrapiFirstSession(response.data.data, currentUser)));

        const instructors = getState().SchoolInstructorPage.instructors;
        const sessionId = response.data.data.id.uuid;
        const lessonId = params.publicData.parentId;
        let allInstructorsData;
        if (params.publicData.dayAvailability && params.publicData.dayAvailability.length && instructors && instructors.length && sessionId && lessonId) {
          allInstructorsData = getAllInstructorsData(params.publicData.dayAvailability, instructors, sessionId, lessonId);

          // add new instructors in strapi 
          allInstructorsData && allInstructorsData.length ? promisify.push(dispatch(onAddInstructorsInSession(allInstructorsData, sessionId))) : null;
        }

        const createExceptionParams = {
          id: response.data.data.id,
          start: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? moment(params.publicData.endDate).add(1, "day").startOf('day').toDate()
            : startDate,
          end: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? moment().add(1, 'year').subtract(2, 'day').startOf('day').toDate()
            : moment(params.publicData.startDate + " 23:55").toDate(),
          seats: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? 0 : (daySingleSeats || 1),
          type: params.publicData.periodicity,
          startDate: startDate
        }

        try {
          const currentUser = getState().user.currentUser;
          const { title } = response.data.data.attributes;
          const url = REACT_APP_MARKETPLACE_ROOT_URL + `/u/${currentUser.id.uuid}`;

          // instructor notification 
          if (allInstructorsData && allInstructorsData.length) {

            const allInstructorsIds = allInstructorsData.filter((st) => st.instructorId).map((st) => st.instructorId);

            allInstructorsIds && allInstructorsIds.length ? promisify.push(onCreateMultipleNotification({
              id: currentUser.id.uuid,
              contentTitle: "Se te ha asignado:  " + title,
              heading: currentUser?.attributes?.profile?.publicData?.schoolName,
              web_url: url,
              type: "SESSION",
              sendSignalNotification: allInstructorsIds
            })) : null;
          }

          // all users notification 
          promisify.push(onCreateMultipleNotification({
            id: currentUser.id.uuid,
            contentTitle: "Apúntate a la nueva sesión " + title,
            heading: currentUser?.attributes?.profile?.publicData?.schoolName,
            web_url: url,
            type: "SESSION"
          }));


        } catch (e) {
          console.log('&&& onCreateMultipleNotification Error &&& => requestPublishListingDraft 707', e);
        }

        await Promise.all(promisify);

        createResponse = response
        return dispatch(createException(createExceptionParams));
      })
      .then((res) => {
        dispatch(createListingSuccess(createResponse));
        return res;
      })
      .catch(e => {
        dispatch(createListingError(storableError(e)));
        return storableError(e);
      });
  }
  catch (e) {
    console.log(e, '&&& CreateListing &&& => e');
    return e;
  }
};

// create exception for session listing creating 
export const createException = (params, timeSlotsId = true) => (dispatch, getState, sdk) => {
  dispatch(exceptionListingRequest(timeSlotsId));
  const { id, start, end, seats = 0, type, startDate } = params;

  return sdk.availabilityExceptions.create({
    listingId: id,
    start: start,
    end: end,
    seats: seats
  }).then(async (res) => {

    if (type == PERIODICITY_TYPE_MULTIPLE && startDate && (moment(startDate).unix() > moment(getCurrentTimeMultipleOfFive()).unix())) {
      try {
        await sdk.availabilityExceptions.create({
          listingId: id,
          start: getCurrentTimeMultipleOfFive(),
          end: startDate,
          seats: 0
        })
      } catch (e) {
        console.log(e);
      }
    }
    dispatch(exceptionListingSuccess(res));
    return res;
  }).catch(e => {
    dispatch(exceptionListingError(storableError(e)));
    return storableError(e);
  });
};

// update the exception of session listing first query then delete previous then create new  
export const updateException = (params) => (dispatch, getState, sdk) => {
  const { id, start, end, seats = 0, type, startDate, endDate } = params;
  return sdk.availabilityExceptions.query({
    listingId: id,
    start: moment().add(1, "day").startOf('day').toDate(),
    end: moment().add(1, 'year').subtract(1, 'day').startOf('day').toDate()
  }).then(async (res) => {
    // first delete the exception if any 
    if (res && res.data?.data?.length) {
      for (let i = 0; i < res.data.data.length; i++) {
        const slotTime = moment(res.data.data[i].attributes.start).unix();
        if (slotTime > moment(startDate).unix() && moment(endDate).unix() > slotTime) {

        } else {
          const deleteException = await sdk.availabilityExceptions.delete({
            id: res.data.data[i].id,
          })
        }
      }
    }

    // then create exception 
    await sdk.availabilityExceptions.create({
      listingId: id,
      start: start,
      end: end,
      seats: seats
    });

    if (type == PERIODICITY_TYPE_MULTIPLE && startDate && (moment(startDate).unix() > moment(getCurrentTimeMultipleOfFive()).unix())) {
      try {
        await sdk.availabilityExceptions.create({
          listingId: id,
          start: getCurrentTimeMultipleOfFive(),
          end: startDate,
          seats: 0
        })
      } catch (e) {
        console.log(e);
      }
    }

    return res;
  }).catch((e) => {
    return storableError(e);
  })
};

// update the session listing 
export const UpdateListing = (params) => (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());
  const listing = getState().SchoolDashBoardPage.listings.find((st) => st.id.uuid == params.id.uuid);

  let updateResponse;

  return sdk.ownListings
    .update({ ...params }, {
      expand: true,
      include: ['author']
    })
    .then(async (response) => {
      // const currentUser = getState().user.currentUser;
      // dispatch(onGetStrapiFirstSession(response.data.data, currentUser))
      // update the exceptions for own listings 
      const daySingleSeats = params.publicData.dayAvailability && params.publicData.dayAvailability.length ? (+params.publicData.dayAvailability[0].seats) : false;
      const instructors = getState().SchoolInstructorPage.instructors;
      const sessionId = response.data.data.id.uuid;
      const lessonId = params.publicData.parentId;
      if (params.publicData.dayAvailability && params.publicData.dayAvailability.length && instructors && instructors.length && sessionId && lessonId) {
        const allInstructorsData = getAllInstructorsData(params.publicData.dayAvailability, instructors, sessionId, lessonId);
        allInstructorsData && allInstructorsData.length ? await dispatch(onAddInstructorsInSession(allInstructorsData, sessionId)) : null;
      }

      const startDate = getSessionStartDate(params.publicData.startDate, params.publicData.dayAvailability);
      const endDate = getSessionPassedDate(params.publicData.endDate, params.publicData.dayAvailability);

      const createExceptionParams = {
        id: response.data.data.id,
        start: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? moment(params.publicData.endDate).add(1, "day").startOf('day').toDate()
          : startDate,
        end: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? moment().add(1, 'year').subtract(2, 'day').startOf('day').toDate()
          : moment(params.publicData.startDate + " 23:55").toDate(),
        seats: (params.publicData.periodicity == PERIODICITY_TYPE_MULTIPLE) ? 0 : (daySingleSeats || 1),
        type: params.publicData.periodicity,
        startDate: startDate,
        endDate: endDate
      }

      try {
        const currentUser = getState().user.currentUser;
        const { title } = response.data.data.attributes;
        const url = REACT_APP_MARKETPLACE_ROOT_URL + `/u/${currentUser.id.uuid}`;
        const notificationPromise = [];

        const {
          deletedInstructors,
          newInstructorsAdded
        } = delNewAddInstructors(listing, response.data.data);

        deletedInstructors && deletedInstructors.length ? notificationPromise.push(onCreateMultipleNotification({
          id: currentUser.id.uuid,
          contentTitle: "Se te ha quitado de:  " + title,
          heading: currentUser?.attributes?.profile?.publicData?.schoolName,
          web_url: url,
          type: "SESSION",
          sendSignalNotification: deletedInstructors
        })) : null;

        newInstructorsAdded && newInstructorsAdded.length ? notificationPromise.push(onCreateMultipleNotification({
          id: currentUser.id.uuid,
          contentTitle: "Se te ha asignado:  " + title,
          heading: currentUser?.attributes?.profile?.publicData?.schoolName,
          web_url: url,
          type: "SESSION",
          sendSignalNotification: newInstructorsAdded
        })) : null;

        await Promise.all(notificationPromise);

      } catch (e) {
        console.log('&&& onCreateMultipleNotification Error &&& => requestPublishListingDraft 707', e);
      }

      updateResponse = response
      return dispatch(updateException(createExceptionParams));
    })
    .then((response) => {
      dispatch(updateListingSuccess(updateResponse));
      return response;
    })
    .catch(e => {
      dispatch(updateListingError(storableError(e)));
      return storableError(e);
    });
};

export const fetchProperTimeSlots = (params) => {
  const { timeSlotsIs, listing } = params;
  const {
    exceptionDays,
    dayAvailability,
    deletedDays = [],
    studentsJoinSession,
    joinSessionPeriodType
  } = listing?.attributes?.publicData || {};
  moment.locale("en")

  if (dayAvailability && timeSlotsIs && dayAvailability.length && timeSlotsIs.length) {
    const newTimeSlot = deletedDays && deletedDays.length ? timeSlotsIs.filter((st) => deletedDays.findIndex((ddd) => moment(ddd * 1000).format('DD-MM-YYYY') == moment(st.attributes.start).format('DD-MM-YYYY')) == -1) : timeSlotsIs;
    return newTimeSlot.filter(st => (studentsJoinSession && joinSessionPeriodType ? moment().add((+studentsJoinSession), joinSessionPeriodType).unix() < moment(st.attributes.start).unix() : true) && (dayAvailability.findIndex((item) => item.day == moment(st.attributes.start).locale('en').format('dddd')) >= 0)).map((st) => {
      const slotDay = moment(st.attributes.start).locale('en').format('dddd');
      const dayAvailabilityIndex = dayAvailability.findIndex((st) => st.day == slotDay);
      const listingData = listing && listing.attributes ? listing?.attributes?.publicData : {};
      const currentListingId = listing && listing.id ? listing.id.uuid : false;
      const { seats, ...rest } = st.attributes
      // adding exceptionTimeSlots
      const alreadyException = exceptionDays && exceptionDays.length ? exceptionDays.findIndex((item) => item.currentDateIs && moment((+item.currentDateIs) * 1000).format("DD-MM-YYYY") == moment(st.attributes.start).format("DD-MM-YYYY")) : -1;

      if (alreadyException >= 0) {
        const exceptionAttributes = { ...listingData, ...exceptionDays[alreadyException], ...rest, currentListingId: currentListingId, timeSlotSeats: seats, seats: exceptionDays[alreadyException].seats }
        return { ...st, attributes: { ...exceptionAttributes } }
      }

      const attributes = { ...dayAvailability[dayAvailabilityIndex], ...st.attributes, currentListingId: currentListingId, ...listingData, timeSlotSeats: seats, seats: dayAvailability[dayAvailabilityIndex].seats }
      return { ...st, attributes: attributes }
    })
  } else {
    return []
  }
}

export const timeSlots = (params, listing) => (dispatch, getState, sdk) => {
  const { listingId, start, end } = params;

  dispatch(timeSlotsFetch(listingId.uuid));
  return sdk.timeslots.query({
    listingId: listingId,
    start: start,
    end: end
  }).then(res => {
    dispatch(timeSlotsFetch(false));
    return fetchProperTimeSlots({ timeSlotsIs: res.data.data, listing: listing });
  }).catch(e => {
    dispatch(timeSlotsFetch(false));
    return storableError(e);
  })
}


export const getAdditionalQuestions = (currentUserId) => (dispatch, getState, sdk) => {
  dispatch(additionalQuestionLoader())
  onGetAdditionalQuestions({ userId: currentUserId }).then((response) => {
    const additionalQuestionIs = Array.isArray(response.data) && response.data.length && Array.isArray(response.data[0].attributes.question) ? response.data[0].attributes.question : [];

    dispatch(additionalQuestion(additionalQuestionIs))
  }).catch(e => {
    dispatch(additionalQuestionError())
  })
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const listingId = new UUID(params.id);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const ownListingQueryParams = {
    id: listingId,
    include: ['images', 'author'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  }

  const { tab } = params;

  const queryListingParams = {
    ...queryParams,
    pub_parentId: params.id,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_sessionDatePassed: tab == "PAST" ? ("," + moment().unix()) : (moment().unix() + ",")
  }

  // for the users which are came from pack of lesson i will add a search Param name packageId
  // {packageId : new UUID(queryParams.packageId) , listingId:listingId , type:"PACK"}
  const userTransactionParams = queryParams && queryParams.packageId ? { listingId: new UUID(queryParams.packageId) } : { listingId: listingId }

  return Promise.all([dispatch(fetchCurrentUser())]).then(() => {
    const currentUser = getState().user.currentUser;
    const userId = currentUser.id.uuid;
    // dispatch(getAdditionalQuestions(userId));
    dispatch(queryOwnListings(ownListingQueryParams, queryListingParams, config))
    // platformLogin
    // const searchFilter = `sort[0]=id:asc&pagination[page]=${1}&pagination[pageSize]=${100}&filters[schoolId][$eq]=${userId}&filters[platformLogin][$eq]=${true}`;
    const searchFilter = schoolInstructorFilterArray(userId);
    dispatch(queryInstructors("", userId, true, searchFilter));
  })
};

export const entries = [
  { dayOfWeek: 'mon', startTime: '00:00', endTime: '23:55', seats: 0, key: "Monday" },
  { dayOfWeek: 'tue', startTime: '00:00', endTime: '23:55', seats: 0, key: "Tuesday" },
  { dayOfWeek: 'wed', startTime: '00:00', endTime: '23:55', seats: 0, key: "Wednesday" },
  { dayOfWeek: 'thu', startTime: '00:00', endTime: '23:55', seats: 0, key: "Thursday" },
  { dayOfWeek: 'fri', startTime: '00:00', endTime: '23:55', seats: 0, key: "Friday" },
  { dayOfWeek: 'sat', startTime: '00:00', endTime: '23:55', seats: 0, key: "Saturday" },
  { dayOfWeek: 'sun', startTime: '00:00', endTime: '23:55', seats: 0, key: "Sunday" },
];

export const makeAvailabity = (values) => {
  if (values && typeof values == "object") {
    let availabilityEntries = [];
    for (const key in values) {
      const currentDayIndex = entries.findIndex((st) => st.key == key);
      if (currentDayIndex >= 0 && values[key]) {

        const currentDayValues = {
          dayOfWeek: entries[currentDayIndex].dayOfWeek,
          startTime: values[key].startTime || "09:00",
          endTime: values[key].endTime || "17:00",
          seats: values.periodicity == PERIODICITY_TYPE_MULTIPLE ? (+values.seats) || (+values[key].seats) || 1 : 0
        }
        if (values.periodicity == PERIODICITY_TYPE_MULTIPLE) {
          availabilityEntries.push(currentDayValues)
        }

        if (values.openAccordion == key && values.periodicity == PERIODICITY_TYPE_SINGLE) {
          availabilityEntries.push(currentDayValues)
        }
      }
    }
    return (availabilityEntries && availabilityEntries.length) ? {
      type: 'availability-plan/time',
      timezone: typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC',
      entries: entries.map(st => {
        if (availabilityEntries.findIndex(item => item.dayOfWeek == st.dayOfWeek) >= 0) {
          return availabilityEntries[availabilityEntries.findIndex(item => item.dayOfWeek == st.dayOfWeek)]
        } else {
          return { dayOfWeek: st.dayOfWeek, startTime: st.startTime, endTime: st.endTime, seats: st.seats };
        }
      })
    } : false;
  } else {
    return false;
  }
}

export const submitFormPublicData = (values) => {
  if (values && typeof values == "object") {
    let formPublicData = {};
    let dayAvailability = [];
    for (const key in values) {
      const currentDayIndex = entries.findIndex((st) => st.key == key);
      if (currentDayIndex >= 0 && values[key] && typeof values[key] == "object") {
        const currentDayValues = {
          ...values[key],
          dayOfWeek: entries[currentDayIndex].dayOfWeek,
          startTime: values[key].startTime || "09:00",
          endTime: values[key].endTime || "17:00",
          seats: (+values.seats) || (+values[key].seats) || 0,
          day: key,
        }
        if (values.periodicity == PERIODICITY_TYPE_MULTIPLE) {
          dayAvailability.push(currentDayValues);
        }

        if (values.openAccordion == key && values.periodicity == PERIODICITY_TYPE_SINGLE) {
          dayAvailability.push(currentDayValues);
        }

      } else {
        formPublicData[key] = values[key];
      }
    }
    return { ...formPublicData, dayAvailability: dayAvailability };
  }
  else {
    return false;
  }
}




export const getParamsSession = (values, pageListing, updatedId) => {
  const parentListing = pageListing;
  // for making availability 
  const availabilityPlans = makeAvailabity({ ...values });
  // for making dayAvailabilty in publicDtaa
  const formValues = submitFormPublicData({ ...values });


  const params = {
    title: formValues.lessonName,
    description: formValues.description || "",
    publicData: {
      ...formValues,
      learningType: SESSION_TYPE,
      parentLessonName: parentListing.attributes.title,
      // isShareable: parentListing.attributes?.publicData?.isShareable || "no",
      parentId: parentListing.id.uuid,
      sessionDatePassed: moment(getSessionPassedDate(formValues.endDate, formValues.dayAvailability)).unix()
    },
    availabilityPlan: availabilityPlans
  }
  if (updatedId) {
    const listingId = new UUID(updatedId);
    Object.assign(params, { id: listingId })
  }
  return params;
}

