export const getUserRole=(user)=>{
    return  user && user?.id && user?.attributes?.profile?.publicData?.userListingType
}

export const getListingType=(listing)=>{
    return  listing && listing?.id && listing?.attributes?.publicData?.listingType 
  }
  
  export const getSchoolListingId = (user)=>{
    return user.id && user?.attributes?.profile?.publicData?.userListingId
  }
  