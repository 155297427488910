import moment from 'moment';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import { types as sdkTypes } from './sdkLoader';
import { COURSE_LESSON_CATEGORY, PACK_OF_LESSON_CATEGORY, SEARCH_TYPE_ADDRESS, SINGLE_LESSON_CATEGORY } from './types';
import uuid4 from 'uuid4';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const makeLocalSaveValues = (values) => {
  const { sport, location, ...rest } = values;
  const { bounds, address } = location.selectedPlace;
  return {
    ...rest,
    pub_sport: sport,
    address: address,
    bounds: bounds,
    type: SEARCH_TYPE_ADDRESS
  }
}

export const makeFormSaveValues = (localValues) => {
  const { address, bounds, origin, pub_sport, ...rest } = localValues;
  return {
    ...rest,
    sport: pub_sport,
    location: {
      search: address,
      predictions: [],
      selectedPlace: {
        address,
        bounds: new SDKLatLngBounds(
          new SDKLatLng(bounds.ne.lat, bounds.ne.lng),
          new SDKLatLng(bounds.sw.lat, bounds.sw.lng)
        )
      }
    }
  }
}

export const makeSearchQuery = (localValues) => {
  const { bounds, ...rest } = localValues;
  return {
    ...rest,
    bounds: new SDKLatLngBounds(
      new SDKLatLng(bounds.ne.lat, bounds.ne.lng),
      new SDKLatLng(bounds.sw.lat, bounds.sw.lng)
    )
  }
}


//  <<<<<<<<<<<<<< userPackages JSON Data >>>>>>>>>>>>>>>>>>>>>>
// {
//   type: "PACK" , "LESSON" , "CASH" , "FREE"
//   listingId: "", packId: "", authorId: "", userId: "", userPackageId: "", schoolName: "" title: "", 
//   price: 5, singlePrice: 4, totalPrice: 3,quantity: 5,totalQuantity: 5, currency: ""
// isShareable cancelPeriodTime cancelPeriodType
// }

// this function is used for create the userPackages of lessons 
export const getInitiateOrderData = (currentUser, currentListing, values, type = false) => {
  const errorResponse = { userPackages: false }
  try {
    const userPackageId = uuid4();
    const userId = currentUser.id.uuid;
    const authorId = currentListing.author.id.uuid;
    const schoolName = currentListing.author.attributes?.profile?.publicData?.schoolName
    const { quantity = 1 } = values || {};
    const listingId = currentListing.id.uuid
    const { publicData, price, title } = currentListing.attributes || {};
    const {
      transactionProcessAlias,
      lessonCategory = false,
      lessonPacks = [],
      isShareable = false,
      cancelPeriodTime = "",
      cancelPeriodType = "",
    } = publicData || {};

    const lessonCategoryPack = lessonCategory == PACK_OF_LESSON_CATEGORY;

    // return value if lesson category is course 
    if (lessonCategory == COURSE_LESSON_CATEGORY) {
      return errorResponse;
    }

    // first return for course if publicData.quantity is zero  or lessonPacks.length is 0
    if (lessonCategoryPack && lessonPacks.length == 0 && (!publicData?.quantity)) {
      return errorResponse;
    }

    // final return value 
    if (price && price.amount && quantity && price.currency && authorId && userId && listingId) {
      const totalPrice = lessonCategoryPack ? parseInt(price.amount) : (price.amount * (+quantity));
      const singlePrice = lessonCategoryPack ? Math.round(totalPrice / publicData.quantity) : parseInt(price.amount);
      const lessonQuantity = lessonCategoryPack ? parseInt(publicData.quantity) : parseInt(quantity)
      const packId = lessonCategoryPack ? lessonPacks.map(st => st.id).join(",") : "";

      const singleLessonData = {
        type: lessonCategoryPack ? PACK_OF_LESSON_CATEGORY : SINGLE_LESSON_CATEGORY,
        listingId: listingId,
        title: title,
        price: totalPrice,
        singlePrice: singlePrice,
        totalPrice: totalPrice,
        quantity: lessonQuantity,
        totalQuantity: lessonQuantity,
        currency: price.currency,
        packId: packId,
        authorId: authorId,
        userId: userId,
        userPackageId: userPackageId,
        schoolName: schoolName,
        isShareable,
        cancelPeriodTime,
        cancelPeriodType,
        guestFirstName: "",
        guestLastName: "",
        guestPhoneNumber: "",
        isGuest: "you"
      };
      // if FREE or CASH 
      if (type && singleLessonData.type == SINGLE_LESSON_CATEGORY) {
        Object.assign(singleLessonData, { type: type })
      }

      return { userPackages: singleLessonData, listingId, processAlias: transactionProcessAlias }
    }

    else {
      return errorResponse
    }

  }

  catch (e) {
    return errorResponse;
  }
}
export const mergePakagesWithSameListingId = (data) => {
  return data.reduce((result, current) => {
    const existingItem = result.find((item) => item.attributes.listingId === current.attributes.listingId);
    if (existingItem) {
      existingItem.attributes.quantity = parseInt(existingItem.attributes.quantity || 0) + parseInt(current.attributes.quantity || 0);
      existingItem.attributes.totalPrice = parseInt(existingItem.attributes.totalPrice || 0) + parseInt(current.attributes.totalPrice || 0);
      existingItem.attributes.totalQuantity = parseInt(existingItem.attributes.totalQuantity || 0) + parseInt(current.attributes.totalQuantity || 0);
      existingItem.attributes.singlePrice = parseInt(existingItem.attributes.singlePrice || 0) + parseInt(current.attributes.singlePrice || 0);
      existingItem.attributes.price = parseInt(existingItem.attributes.price || 0) + parseInt(current.attributes.price || 0);
    } else {
      result.push({ ...current });
    }
    return result;
  }, []);
}


// <<<<<<<<<<<<<<<<<<<<<< session Transaction JSON Data  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// {
//   transactionId:"" , listingId: "", userPackageId:"", sessionId:"", chatId:"", userId:"", authorId:"", type:"", unixDate:5, date:"", isCancelled: "",
//   additionalInformation: {},
//   isGuest: false,
//   guestFirstName: "",
//   guestLastName: "",
//   guestPhoneNumber: "",
// }

export const onCreateSessionTransactionData = (values, currentUser) => {
  try {
    const {
      userPackages,
      parentId,
      start,
      end,
      timeSlotSeats,
      currentListingId,
      userPickupPoint,
      userPickupPointTime,
      userPickupPointAddress = "",
      userPickupPointURL = "",
      joiningSession = "you",
      joiningSessionGuest,
      additionalQuestions,
      guestFirstName = "",
      guestLastName = "",
      guestPhoneNumber = "",
      lessonName
    } = values;

    const {
      transactionId,
      userId,
      authorId,
      quantity,
      isShareable = false,
      cancelPeriodTime = "",
      cancelPeriodType = "",
    } = userPackages.attributes;

    const cancelPeriodTimeMaybe = values.cancelPeriodTime ?  values.cancelPeriodTime : cancelPeriodTime;
    const cancelPeriodTypeMaybe = values.cancelPeriodType ? values.cancelPeriodType : cancelPeriodType

    const { firstName, lastName, publicData } = currentUser.attributes.profile;

    const isReview = false;
    const userName = firstName + " " + lastName;
    const userPhoneNumber = publicData?.phoneNumber || "";

    const sessionTransactionJson = {
      transactionId,
      listingId: currentListingId,
      userPackageId: userPackages.id,
      sessionId: uuid4(),
      chatId: "",
      userId,
      authorId,
      unixDate: moment(end).locale('en').unix(),
      date: moment(start).locale('en').format("DD-MM-YYYY"),
      type: "LESSON",
      additionalInformation: { userPickupPoint, userPickupPointTime, userPickupPointAddress, additionalQuestions, userPickupPointURL },
      isGuest: joiningSession,
      guestFirstName: joiningSession == "you" ? "" : guestFirstName,
      guestLastName: joiningSession == "you" ? "" : guestLastName,
      guestPhoneNumber: joiningSession == "you" ? "" : guestPhoneNumber,
      isReview,
      userName,
      userPhoneNumber,
      isShareable,
      cancelPeriodTime:cancelPeriodTimeMaybe,
      cancelPeriodType:cancelPeriodTypeMaybe
    };  

    const updatedUserPackagesData = { quantity: (+quantity) - 1, id: userPackages.id };
    const availabiltyException = { id: currentListingId, start, end, seats: parseInt(timeSlotSeats) - 1 }
    return { sessionTransactionJson, updatedUserPackagesData, availabiltyException, sessionName: lessonName };
  } catch (e) {
    return { sessionTransactionJson: false, updatedUserPackagesData: false };
  }
}
export const getListingDataForMail = (listing, currentUser) => {
  try {
    const { attributes, author, images, id } = listing;
    const { schoolName = "" } = author?.attributes?.profile?.publicData || {};
    const { title } = attributes;
    const { email } = currentUser.attributes;
    const { firstName, lastName, publicData } = currentUser.attributes.profile || {};
    const { phoneNumber = "" } = publicData;
    const userName = firstName + " " + lastName;
    const listingImageUrl = (images && images.length && images[0].attributes?.variants["listing-card"]?.url) || "";
    return { schoolName, title, listingImageUrl, userName, userPhoneNumber: phoneNumber, listingId: id.uuid, userEmail: email, authorId: author?.id?.uuid }
  } catch (e) {
    return {};
  }
}

export const getUpdateQuestionsInUserProfile = (sessionTransactionJson, currentUser) => {
  try {
    const additionalQuestions = sessionTransactionJson?.additionalInformation?.additionalQuestions || [];
    const oldAdditionalQuestions = currentUser?.attributes?.profile?.publicData?.customQuestion || [];

    // Create a lookup map with old questions
    const questionLookup = oldAdditionalQuestions.reduce((acc, { question, answer }) => {
      acc[question] = answer;
      return acc;
    }, {});

    // Update the lookup map with new questions
    additionalQuestions.forEach(({ key: question, value: answer }) => {
      questionLookup[question] = answer;
    });

    // Convert the lookup map to an array of objects and trim to the most recent 30 entries
    const combinedResult = Object.entries(questionLookup)
      .map(([question, answer]) => ({ question, answer }))
      .slice(-30);

    return combinedResult;
  } catch (e) {
    console.error('Error processing user profile questions:', e);
    return [];
  }
};



export const getFollowingArrays = (currentUser, id, value) => {
  const { followingArray = [], followingArraySearch = [] } = currentUser?.attributes?.profile?.publicData || {};
  let newFollowingArray = [];
  let newfollowingArraySearch = [];
  console.log(value, '&&& &&& => value');

  if (Array.isArray(followingArray)) {

    newFollowingArray = followingArray.length && value == "unfollow" && followingArray.findIndex((st) => st.id == id) >= 0
      ? followingArray.filter((st) => st.id != id)
      : followingArray.length && followingArray.findIndex((st) => st.id == id) >= 0
        ? followingArray.map((st) => st.id == id ? { ...st, value: value } : st)
        : [...followingArray, { value: value, id: id }];

    newfollowingArraySearch = followingArraySearch.length && value == "unfollow" && followingArraySearch.findIndex((st) => st == id) >= 0 ? followingArraySearch.filter((st) => st != id)
      : followingArraySearch.length && followingArraySearch.findIndex((st) => st == id) >= 0 ? [...followingArraySearch] : [...followingArraySearch, id];

  } else {
    newFollowingArray.push({ value: value, id: id });
    newfollowingArraySearch.push(id);
  }

  return { newFollowingArray, newfollowingArraySearch }
}



export const getAllInstructorsData = (dayAvailability, stateInstructors, sessionId, lessonId) => {
  const dayAvailabilityInstuctors = [];
  for (let i = 0; i < dayAvailability.length; i++) {
    const eachInstructor = dayAvailability[i]?.instructors && dayAvailability[i]?.instructors.length ? dayAvailability[i]?.instructors : [];

    eachInstructor.forEach((st) => {
      dayAvailabilityInstuctors.includes(st.value) ? null : dayAvailabilityInstuctors.push(st.value);
    })
  }

  const newInstructor = dayAvailabilityInstuctors && dayAvailabilityInstuctors.length ? stateInstructors.filter((st) => dayAvailabilityInstuctors.includes(st.attributes.instructorId)) : []

  return newInstructor && newInstructor.length && sessionId && lessonId ? newInstructor.map((st) => {
    const { platformLogin, createdAt, updatedAt, publishedAt, ...rest } = st.attributes;
    return { sessionId, lessonId, ...rest }
  }) : [];
};


export const delNewAddInstructors = (obj1, obj2) => {
  const previousInstructors = [];
  const newInstructors = [];
  const dayAvailability1 = obj1.attributes?.publicData?.dayAvailability;
  const dayAvailability2 = obj2.attributes?.publicData?.dayAvailability;

  dayAvailability1 && dayAvailability1.length && dayAvailability1.forEach((item) => {
    item && item.instructors.forEach(ist => ist.value && (!previousInstructors.includes(ist.value)) && previousInstructors.push(ist.value));
  });

  dayAvailability2 && dayAvailability2.length && dayAvailability2.forEach((item) => {
    item && item.instructors.forEach(ist => ist.value && (!newInstructors.includes(ist.value)) && newInstructors.push(ist.value));
  });

  const deletedInstructors = previousInstructors.filter((st) => !newInstructors.includes(st));
  const newInstructorsAdded = newInstructors.filter((st) => !previousInstructors.includes(st));

  return {
    deletedInstructors,
    newInstructorsAdded
  }
}


export const getUserInstNotCompare = (obj1, obj2) => {
  const updatedFields = {};
  const deletedInstructors = [];
  const newInstructorsAdded = [];

  // Compare fields in obj1 and obj2
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key)) {
        updatedFields[key] = "Removed in obj2";
      } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        updatedFields[key] = "Different values";
      }
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      updatedFields[key] = "Added in obj2";
    }
  }

  // Compare instructors arrays
  const instructors1 = obj1.instructors && obj1.instructors.length ? obj1.instructors.map(instructor => JSON.stringify(instructor)) : [];
  const instructors2 = obj1.instructors && obj1.instructors.length ? obj2.instructors.map(instructor => JSON.stringify(instructor)) : [];

  instructors1.forEach(instructor => {
    if (!instructors2.includes(instructor)) {
      deletedInstructors.push(JSON.parse(instructor));
    }
  });

  instructors2.forEach(instructor => {
    if (!instructors1.includes(instructor)) {
      newInstructorsAdded.push(JSON.parse(instructor));
    }
  });

  const studentUpadteCheckArray = ["endTime", "startTime", "additionalLinks", "locationURL"];
  const studentUpdateCheck = Object.keys(updatedFields).findIndex((st) => studentUpadteCheckArray.includes(st)) >= 0

  return {
    studentUpdateCheck,
    deletedInstructors: deletedInstructors.map((st) => st.value),
    newInstructorsAdded: newInstructorsAdded.map((st => st.value))
  };
}

// Group messages by date format ( 'YYYY-MM-DD') 
export const groupMessagesByDate = (messages) => {
  return messages.reduce((grouped, msg) => {
    const date = moment(msg.attributes.createdAt).format('DD/MM/YY');
    // If the date doesn't exist in the grouped object, create an empty array for it
    if (!grouped[date]) {
      grouped[date] = [];
    }
    // Push the current message to the array of messages for that date
    grouped[date].push(msg);
    return grouped;
  }, {});
}