import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { LESSON_TYPE, PACK_OF_LESSON_CATEGORY, SESSION_TYPE, COURSE_LESSON_CATEGORY } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import moment from 'moment';

const { UUID } = sdkTypes;
const RESULT_PAGE_SIZE = 100;


export const SCHOOL_PLANNING_PAGE_REQUEST = 'app/SchoolPlanningPage/SCHOOL_PLANNING_PAGE_REQUEST';

export const QUERY_LISTINGS_REQUEST = 'app/SchoolPlanningPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/SchoolPlanningPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/SchoolPlanningPage/QUERY_LISTINGS_ERROR';


const initialState = {
    schoolPlanningPageLoading: false,
    queryListingsRequest: false,
    lessonListings: [],
    combineCourseSessionListings: [],
    queryListingsError: null,
};

export default function schoolPlanningPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SCHOOL_PLANNING_PAGE_REQUEST:
            return {
                ...state,
                schoolPlanningPageLoading: payload
            }
        case QUERY_LISTINGS_REQUEST:
            return {
                ...state,
                queryListingsRequest: true,
                queryListingsError: null,
            };
        case QUERY_LISTINGS_SUCCESS:
            const { combineCourseSessionListings, lessonListings } = payload;
            return { ...state, combineCourseSessionListings, lessonListings };
        case QUERY_LISTINGS_ERROR:
            return { ...state, userListingRefs: [], queryListingsError: payload };
        default:
            return state;
    }
}

export const schoolPlanningPageLoading = (data) => ({
    type: SCHOOL_PLANNING_PAGE_REQUEST,
    payload: data
})

export const queryListingsRequest = userId => ({
    type: QUERY_LISTINGS_REQUEST,
    payload: { userId },
});

export const queryListingsSuccess = (data) => ({
    type: QUERY_LISTINGS_SUCCESS,
    payload: data,
});

export const queryListingsError = e => ({
    type: QUERY_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const queryUserListings = (userId, config, lessonPagination) => (dispatch, getState, sdk) => {
    try {
        dispatch(queryListingsRequest(userId));
        return sdk.listings
            .query({
                author_id: userId,
                pub_learningType: LESSON_TYPE,
                ...lessonPagination
            })
            .then(async (response) => {
                const filterPackListings = response.data.data.filter(item => item?.attributes?.publicData?.lessonCategory != PACK_OF_LESSON_CATEGORY);

                if (filterPackListings && filterPackListings.length) {
                    const courseListingData = filterPackListings.filter(item => item?.attributes?.publicData?.lessonCategory == COURSE_LESSON_CATEGORY);
                    const sessionListings = await sdk.listings.query({
                        author_id: userId,
                        pub_learningType: SESSION_TYPE,
                        // pub_sessionDatePassed: (moment().unix() + ","),
                        ...lessonPagination
                    });
                    const combineCourseSession = (sessionListings && sessionListings.data.data.length)
                        ? [...courseListingData, ...sessionListings.data.data]
                        : courseListingData;

                    const combineCourseSessionListings = combineCourseSession.length ? combineCourseSession.map((item) => {
                        if (item?.attributes?.publicData?.lessonCategory === "COURSE") {
                            return { ...item, listingId: item.id.uuid, searchId: item.id.uuid }
                        }
                        else {
                            return { ...item, listingId: item?.attributes?.publicData?.parentId, searchId: item.id.uuid }
                        }
                    }).filter((st) => st.listingId) : [];

                    dispatch(queryListingsSuccess({
                        combineCourseSessionListings,
                        lessonListings: filterPackListings
                    }));

                    return response;
                } else {
                    dispatch(queryListingsSuccess({ combineCourseSessionListings: [], lessonListings: [] }));
                    return response;
                }
            })
            .catch(e => dispatch(queryListingsError(storableError(e))));
    }
    catch (e) {
        dispatch(queryListingsError(storableError(e)))
        return
    }
};


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    try {
        const queryParams = parse(search);
        const page = queryParams.page || 1;

        const { checkedItemsIds, calenderView } = queryParams || {};
        if (checkedItemsIds || calenderView) {

        } else {
            dispatch(schoolPlanningPageLoading(true));
        }


        const lessonPagination = {
            page,
            perPage: RESULT_PAGE_SIZE,
        }

        return Promise.all([dispatch(fetchCurrentUser())])
            .then(async (res) => {
                const currentUser = getState().user?.currentUser;
                const userId = currentUser?.id?.uuid;
                if (userId) {
                    await dispatch(queryUserListings(userId, config, lessonPagination));
                }
                dispatch(schoolPlanningPageLoading(false));
            }).catch((e) => {
                dispatch(schoolPlanningPageLoading(false));
            })
    } catch (e) {
        dispatch(schoolPlanningPageLoading(false));
    }
};