import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;
import { types as sdkTypes } from '../../util/sdkLoader';

import { transitions } from '../../transactions/transactionProcessPurchase';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/DashBoardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/DashBoardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/DashBoardPage/FETCH_LISTINGS_ERROR';
const { UUID } = sdkTypes;

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryListingsError: null,
  listingIds:[],
  queryParams:null,
};


const DashBoardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        listings:[],
        queryParams:payload,
        queryListingsError: null
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listingIds: payload.data.data.map(l => l.id),
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload , listings:[] };

    default:
      return state;
  }
};

export default DashBoardPageReducer;

// ================ Selectors ================ //


// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryListings = (queryParams,loadDataParams,config) => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

    return sdk.listings
      .query({ authorId: loadDataParams.userId, pub_learningType: "SESSION"  , include: ['author', 'images'],})
      .then(response => {
        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };
        dispatch(addMarketplaceEntities(response, sanitizeConfig));
        dispatch(queryListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(queryListingsError(storableError(e)));
        throw e;
      });
};

export const updateTransistion = params => async (dispatch, getState, sdk) => {
  const {txID} = params
  return;
  // return sdk.transactions.transition({
  //   id: txID,
  //   transition: transitions.LESSON_COMPLETED_CUSTOMER,
  //   params: {}
  // })
  //   .then(response => {
  //     console.log(response, '&&& &&& => response');
  //   }).catch(e => {
  //     console.log(e, "e")
  //   });
  }


export const loadData = (params, search, config) =>  {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

    return queryListings({
      ...queryParams,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['images', 'currentStock'],
      "fields.listing": [
        "title",
        "geolocation",
        "price",
        "publicData",
      ],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },params,config);
  
};
