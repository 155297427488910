import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import { onGetUserPackages } from '../../util/api';
import { mergePakagesWithSameListingId } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getProperResponseOfListing } from '../ManageListingsPage/ManageListingsPage.duck';

const RESULT_PAGE_SIZE = 100;

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
// ================ Action types ================ //


export const QUERY_USER_TRANSACTION_REQUEST = 'app/StudentSchool/QUERY_USER_TRANSACTION_REQUEST';
export const QUERY_USER_TRANSACTION_SUCCESS = 'app/StudentSchool/QUERY_USER_TRANSACTION_SUCCESS'
export const QUERY_USER_TRANSACTION_ERROR = 'app/StudentSchool/QUERY_USER_TRANSACTION_ERROR';

export const USER_PACKAGES_REQUEST = 'app/StudentSchool/USER_PACKAGES_REQUEST';
export const USER_PACKAGES_SUCCESS = 'app/StudentSchool/USER_PACKAGES_SUCCESS';
export const USER_PACKAGES_ERROR = 'app/StudentSchool/USER_PACKAGES_ERROR';

export const STUDENT_SCHOOL_LOADER = 'app/StudentSchool/STUDENT_SCHOOL_LOADER';

// ================ Reducer ================ //

const initialState = {
  userTransactionsRequest: true,
  userTransactions: [],
  userTransactionsError: false,
  pagination: null,
  userPackagesRequest: true,
  userPackages: [],
  userPackagesError: false,
  pageLoader:false,
};

export default function StudentSchoolReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_USER_TRANSACTION_REQUEST:
      return { ...state, userTransactionsRequest: true };
    case QUERY_USER_TRANSACTION_SUCCESS:
      const transactions = sortedTransactions(payload.data.data);
      return { ...state, userTransactions: entityRefs(transactions), userTransactionsRequest: false, pagination: payload.data.meta }
    case QUERY_USER_TRANSACTION_ERROR:
      return { ...state, userTransactionsError: payload, userTransactionsRequest: false };
    case USER_PACKAGES_REQUEST:
      return { ...state, userPackagesRequest: true };
    case USER_PACKAGES_SUCCESS:
      return { ...state, userPackages: payload, userPackagesRequest: false }
    case USER_PACKAGES_ERROR:
      return { ...state, userPackagesError: payload, userPackagesRequest: false }
    
    case STUDENT_SCHOOL_LOADER: 
      return {...state , pageLoader:payload}

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const queryUserTransactionsRequest = () => ({
  type: QUERY_USER_TRANSACTION_REQUEST,
});

export const queryUserTransactionsSuccess = (data) => ({
  type: QUERY_USER_TRANSACTION_SUCCESS,
  payload: data,
})

export const queryUserTransactionsError = e => ({
  type: QUERY_USER_TRANSACTION_ERROR,
  payload: e,
});

export const userPackagesSuccess = (data) => ({
  type: USER_PACKAGES_SUCCESS,
  payload: data,
});

export const userPackagesError = e => ({
  type: USER_PACKAGES_ERROR,
  payload: e,
});
export const userPackagesRequest = () => ({
  type: USER_PACKAGES_REQUEST,
});

export const pageLoader = (data)=>({
  type:STUDENT_SCHOOL_LOADER,
  payload:data
})

// ================ Thunks ================ //
const includeTransactionsParams = ['customer', 'customer.profileImage', 'provider', 'provider.profileImage', 'listing', 'listing.currentStock', 'booking', 'reviews', 'reviews.author', 'reviews.subject'];
const fieldsImageIncludes = [
  // Scaled variants for large images
  'variants.scaled-small',
  'variants.scaled-medium',
  'variants.scaled-large',
  'variants.scaled-xlarge',
  // Social media
  'variants.facebook',
  'variants.twitter',
  // Avatars
  'variants.square-small',
  'variants.square-small2x',
];

const SessionCourseLastTransaction = ["transition/confirm-payment", "transition/lesson-completed-customer", "transition/review-1-by-customer", "transition/expire-customer-review-period", "transition/expire-review-period"];

export const queryUserTransactions = (pageParams) => async (dispatch, getState, sdk) => {
  dispatch(queryUserTransactionsRequest())
  return sdk.transactions.query({
    processNames: "session-course",
    only: "order",
    lastTransition: SessionCourseLastTransaction,
    include: includeTransactionsParams,
    'fields.image': fieldsImageIncludes,
    ...pageParams
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryUserTransactionsSuccess(response));
      return response;
    }).catch((e) => {
      return dispatch(queryUserTransactionsError(storableError(e)));
    })
};


export const userPackages = (userId, pageParams, config) => async (dispatch, getState, sdk) => {
  dispatch(userPackagesRequest())
  const { page, perPage } = pageParams || {};
  const searchFilter = `pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[userId][$eq]=${userId}&filters[quantity][$gt]=0`;

  return onGetUserPackages({ searchFilter }).then(async (response) => {
    try{
      if (response.data && response.data.length) {
        const allPackages = mergePakagesWithSameListingId(response.data);
        const allListingIds = allPackages.map((st) => st.attributes.listingId);
        const queryListings = await sdk.listings.query({ ids: allListingIds.join(","), include: ['author', 'author.profileImage']  ,'fields.image': [
          // Scaled variants for large images
          'variants.scaled-small',    
          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ]});
        const queryListingRespopnse = getProperResponseOfListing(queryListings, config);
        const mergedData = allPackages.map(obj1 => {
          const filteredAuthorIndex = queryListingRespopnse.findIndex(obj2 => obj1?.attributes?.listingId === obj2?.id?.uuid);
          if (filteredAuthorIndex >= 0) {
            return { ...obj1, provider: queryListingRespopnse[filteredAuthorIndex].author, type: "LESSON" }
          } else {
            return { ...obj1 }
          }
        })
        dispatch(userPackagesSuccess(mergedData));
      } else {
        dispatch(userPackagesSuccess([]));
        return [];
      }
    }
    catch(e){
      console.log(e, 'err');
      return e;
    }
  })
    .catch(e => {
      dispatch(userPackagesError(e))
    })
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(pageLoader(true));
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const pageParams = {
    page,
    perPage: RESULT_PAGE_SIZE,
  }
  
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(async (response) => {
      const currentUser = getState().user.currentUser;
      const userId = currentUser.id.uuid;
     await dispatch(queryUserTransactions(pageParams));
      if (userId) {
       await dispatch(userPackages(userId, pageParams, config));
      }
      dispatch(pageLoader(false));
    });
};
