import moment from 'moment';

import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { onCreateAvalabityException, onCreateNotification, onGetSessionTransaction, onGetUserPackages, onSessionCancelMailUser, onUpdateSessionTransaction, onUpdateUserPackages } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
const { UUID } = sdkTypes;
const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const RESULT_PAGE_SIZE = 100;


// ================ Action types ================ //

export const QUERY_USER_TRANSACTION_REQUEST = 'app/BookingPage/QUERY_USER_TRANSACTION_REQUEST';
export const QUERY_USER_TRANSACTION_SUCCESS = 'app/BookingPage/QUERY_USER_TRANSACTION_SUCCESS'
export const QUERY_USER_TRANSACTION_ERROR = 'app/BookingPage/QUERY_USER_TRANSACTION_ERROR';

export const QUERY_SUBSCRIPTION_TRANSACTION_REQUEST = 'app/BookingPage/QUERY_SUBSCRIPTION_TRANSACTION_REQUEST';
export const QUERY_SUBSCRIPTION_TRANSACTION_SUCCESS = 'app/BookingPage/QUERY_SUBSCRIPTION_TRANSACTION_SUCCESS'
export const QUERY_SUBSCRIPTION_TRANSACTION_ERROR = 'app/BookingPage/QUERY_SUBSCRIPTION_TRANSACTION_ERROR';

export const QUERY_SESSION_REQUEST = 'app/BookingPage/QUERY_SESSION_REQUEST';
export const QUERY_SESSION_SUCCESS = 'app/BookingPage/QUERY_SESSION_SUCCESS'
export const QUERY_SESSION_ERROR = 'app/BookingPage/QUERY_SESSION_ERROR';

export const UPDATE_META_REQUEST = 'app/BookingPage/UPDATE_META_REQUEST';
export const CANCEL_TRANSACTION_REQUEST = 'app/BookingPage/CANCEL_TRANSACTION_REQUEST'


// ================ Reducer ================ //

const initialState = {
  userTransactionsRequest: true,
  userTransactions: [],
  userTransactionsError: false,
  userSessionRequest: false,
  userSession: [],
  userSessionError: false,
  sessionTimeSlots: [],
  pagination: null,
  updateMetaRequest: false,
  cancelRequest: false
};

export default function BookingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_USER_TRANSACTION_REQUEST:
      return { ...state, userTransactionsRequest: true };
    case QUERY_USER_TRANSACTION_SUCCESS:
      // const transactions = sortedTransactions(payload.data);
      const transactions = payload;
      // return { ...state, userTransactions: transactions, userTransactionsRequest: false, pagination: payload.data.meta }
      return { ...state, userTransactions: transactions, userTransactionsRequest: false, pagination: payload.id }
    case QUERY_USER_TRANSACTION_ERROR:
      return { ...state, userTransactionsError: payload, userTransactionsRequest: false };

    case QUERY_SESSION_REQUEST:
      return { ...state, userSessionRequest: true };
    case QUERY_SESSION_SUCCESS:
      return { ...state, userSession: payload.userSession, sessionTimeSlots: payload.sessionTimeSlots, userSessionRequest: false }
    case QUERY_SESSION_ERROR:
      return { ...state, userSessionError: payload, userSessionRequest: false };

    case UPDATE_META_REQUEST:
      return { ...state, updateMetaRequest: payload }

    case CANCEL_TRANSACTION_REQUEST:
      return { ...state, cancelRequest: payload }

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const queryUserTransactionsRequest = () => ({
  type: QUERY_USER_TRANSACTION_REQUEST,
});

export const queryUserTransactionsSuccess = (data) => ({
  type: QUERY_USER_TRANSACTION_SUCCESS,
  payload: data,
})

export const queryUserTransactionsError = e => ({
  type: QUERY_USER_TRANSACTION_ERROR,
  payload: e,
});


export const updateMetaRequest = (data) => ({
  type: UPDATE_META_REQUEST,
  payload: data
});

export const cancelRequest = (data) => ({
  type: CANCEL_TRANSACTION_REQUEST,
  payload: data
});


// ================ Thunks ================ //

const onSendCancelMail = (cancelEmailData, sdk) => {
  const { parentId = false, ...rest } = cancelEmailData
  if (parentId) {
    sdk.listings.show({ id: new UUID(parentId), include: ["images", "author"] })
      .then(res => {
        console.log(res, '&&& &&& => res');
        const lessonUrl = (res.data && res.data.included && res.data.included.length && res.data.included.filter((st) => st.type = "image").map(st => st.attributes?.variants?.default?.url || "").join("")) || "";
        const schoolName = (res.data && res.data.included && res.data.included.length && res.data.included.filter((st) => st.type = "image").map(st => st.attributes?.profile?.publicData?.schoolName || "").join("")) || "";
        const sessionCancelMailJson = { ...rest, schoolName, lessonUrl };
        onSessionCancelMailUser(sessionCancelMailJson)
      });
  }
}



// SESSION_BOOKING
export const onCancelTransaction = (metaParams, cancelEmailData) => async (dispatch, getState, sdk) => {
  try {
    const { id, packageID } = metaParams;
    dispatch(cancelRequest(id));
    const { listingId, sessionDate } = cancelEmailData;
    const currentSlotSeats = await sdk.timeslots.query({
      listingId: new UUID(listingId),
      start: moment().toDate(),
      end: moment().add(90, "days").toDate()
    });
   
    const slotData = currentSlotSeats.data.data.find((st) => moment(st.attributes.start).format("DD-MM-YYYY") == sessionDate);
    const { seats, start, end } = slotData.attributes;
    const availabiltyException = { id:listingId, start, end, seats: seats + 1 };

    const page = 1;
    const searchFilter = `pagination[page]=${page}&pagination[pageSize]=${RESULT_PAGE_SIZE}&filters[id][$eq]=${packageID}`;
    return onCreateAvalabityException(availabiltyException)
      .then(() => {
        return onGetUserPackages({ searchFilter })
      }).then(resp => {
        const { quantity } = resp?.data[0].attributes || {};
        const updatedQuantity = quantity + 1;
        return onUpdateUserPackages({ id: packageID, quantity: updatedQuantity })
      })
      .then(res => {
        console.log(res.data);
        return onUpdateSessionTransaction({ id: id, isCancelled: true, isCancelledBy: "USER" })
      })
      .then(async (res) => {
        // await onSendCancelMail(cancelEmailData, sdk);

        try {
          const { authorId = "11", sessionTitle = "", userName, isGuest, guestFirstName = "", guestLastName = "", listingId, startDate } = cancelEmailData || {};
          // const joinedUser = isGuest == "you"? userName : guestFirstName + " " + guestLastName
          // ?calendarDate=2024-03-14
          await onCreateNotification({
            external_id: [authorId],
            contentTitle: userName + " canceló su asistencia a " + sessionTitle,
            heading: "Asistencia cancelada",
            web_url: REACT_APP_MARKETPLACE_ROOT_URL + "/list-dashboard/" + listingId + "?calendarDate=" + moment(startDate).format("YYYY-MM-DD")
          })
        } catch (e) {
          console.log('&&& onCreateNotification Error &&& => onFreeLessonIntiateOrder 351', e);
        }

        dispatch(cancelRequest(false));
        return { status: 200, message: "update successful" };
      })

  } catch (e) {
    dispatch(cancelRequest(false));
    console.log(e, 'error');
    return storableError(e);
  }
};

export const onUpdateMetaDataTransaction = (metaParams) => async (dispatch, getState, sdk) => {
  const { requestLoader, id, ...rest } = metaParams;
  const params = { tab: "pending" };
  const search = {};
  try {
    dispatch(updateMetaRequest(requestLoader || true));
    return onUpdateSessionTransaction({ id, ...rest })
      .then(async response => {
        dispatch(updateMetaRequest(false));
        await dispatch(loadData(params, search))
        console.log(response, 'resppty');
        return { status: 200, data: response };
      });
  } catch (e) {
    console.error(e, '&&& &&& => e');
    dispatch(updateMetaRequest(false));
    return storableError(e);
  }
};


export const queryUserTransactions = (params, search, userId) => async (dispatch, getState, sdk) => {
  dispatch(queryUserTransactionsRequest())
  const { tab = "pending" } = params;
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const perPage = RESULT_PAGE_SIZE;
  const time = moment().unix();
  const searchFilter = tab == "pending" ? `pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[userId][$eq]=${userId}&filters[unixDate][$gt]=${time}&filters[isCancelled][$eq]=${false}`
    : tab == "done" ? `pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[userId][$eq]=${userId}&filters[unixDate][$lt]=${time}&filters[isCancelled][$eq]=${false}`
      : `pagination[page]=${page}&pagination[pageSize]=${perPage}&filters[userId][$eq]=${userId}&filters[isCancelled][$eq]=${true}`;

  return onGetSessionTransaction({ searchFilter }).then(async (response) => {
    try {
      if (response.data && response.data.length) {
        const allListingIds = response.data.map((st) => st.attributes.listingId);
        const queryListings = await sdk.listings.query({ ids: allListingIds.join(",") , include:"author" });
     
        const mergedData = response.data.map(obj1 => {
          const filteredAuthorIndex = queryListings.data.data.findIndex(obj2 => obj1?.attributes?.listingId === obj2?.id?.uuid);
          if (filteredAuthorIndex >= 0) {
            const listingAuthorID = queryListings.data.data[filteredAuthorIndex].relationships?.author?.data?.id?.uuid;
            const checkAuthorData = queryListings.data.included && queryListings.data.included.length && listingAuthorID ? queryListings.data.included.filter((st)=> st?.id?.uuid==listingAuthorID) : [];
            const authorDataMaybe = checkAuthorData && checkAuthorData.length ? {authorAttributes:checkAuthorData[0]} : {}
            return { ...obj1, listingAttributes: queryListings.data.data[filteredAuthorIndex].attributes, ...authorDataMaybe }
          } else {
            return { ...obj1 }
          }
        })
        dispatch(queryUserTransactionsSuccess(mergedData));
        return mergedData;
      } else {
        dispatch(queryUserTransactionsSuccess([]));
        return [];
      }

    }
    catch (err) {
      console.log(err, 'error');
      return err;
    }

  }).catch((e) => {
    return dispatch(queryUserTransactionsError(storableError(e)));
  });
};


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())]).then(res => {
    const currentUser = getState().user.currentUser;
    const userId = currentUser.id.uuid;
    if (userId) {
      dispatch(queryUserTransactions(params, search, userId));
    }
  })
};



