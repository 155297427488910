import pick from 'lodash/pick';
import { initiatePrivileged, onCreateNotification, onCreateSubscription, onCreateUserPackages, transitionPrivileged, updateMetaDataApi, updateStrapiFreeLessonsData } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { COURSE_LESSON_CATEGORY, PACK_OF_LESSON_CATEGORY, SINGLE_LESSON_CATEGORY } from '../../util/types';

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const INITIATE_FREE_LESSON_REQUEST = 'app/CheckoutPage/INITIATE_FREE_LESSON_REQUEST';
export const INITIATE_FREE_LESSON_SUCCESS = 'app/CheckoutPage/INITIATE_FREE_LESSON_SUCCESS';
export const INITIATE_FREE_LESSON_ERROR = 'app/CheckoutPage/INITIATE_FREE_LESSON_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_CASH_LESSON_REQUEST = 'app/CheckoutPage/INITIATE_CASH_LESSON_REQUEST';
export const INITIATE_CASH_LESSON_SUCCESS = 'app/CheckoutPage/INITIATE_CASH_LESSON_SUCCESS';
export const INITIATE_CASH_LESSON_ERROR = 'app/CheckoutPage/INITIATE_CASH_LESSON_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  initiateFreeLessonError: null,
  initiateFreeLessonRequest: false,
  initiateCashLessonError: null,
  initiateCashLessonRequest: false,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload, initiateCashLessonError: null, initiateCashLessonRequest: false, initiateFreeLessonError: null, initiateFreeLessonRequest: false };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case INITIATE_FREE_LESSON_REQUEST:
      return { ...state, initiateFreeLessonError: null, initiateFreeLessonRequest: true };
    case INITIATE_FREE_LESSON_SUCCESS:
      return { ...state, transaction: payload, initiateFreeLessonRequest: false };
    case INITIATE_FREE_LESSON_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateFreeLessonError: payload, initiateFreeLessonRequest: false };

    case INITIATE_CASH_LESSON_REQUEST:
      return { ...state, initiateCashLessonError: null, initiateCashLessonRequest: true };
    case INITIATE_CASH_LESSON_SUCCESS:
      return { ...state, initiateCashLessonRequest: false, initiateCashLessonError: null, transaction: payload };
    case INITIATE_CASH_LESSON_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateCashLessonError: payload, initiateCashLessonRequest: false };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });


const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const initiateFreeLessonRequest = () => ({ type: INITIATE_FREE_LESSON_REQUEST });
const initiateFreeLessonSuccess = order => ({
  type: INITIATE_FREE_LESSON_SUCCESS,
  payload: order,
});

const initiateFreeLessonError = e => ({
  type: INITIATE_FREE_LESSON_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

const initiateCashLessonRequest = () => ({ type: INITIATE_CASH_LESSON_REQUEST });
const initiateCashLessonSuccess = order => ({
  type: INITIATE_CASH_LESSON_SUCCESS,
  payload: order,
});

const initiateCashLessonError = e => ({
  type: INITIATE_CASH_LESSON_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'customer'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      // listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged

    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};


export const onFreeLessonIntiateOrder = (
  orderParams,
  processAlias,
  requestTransition,
) => async (dispatch, getState, sdk) => {
  dispatch(initiateFreeLessonRequest());

  const { quantity, userPackages, isFreeLesson, listingId, listingDataMail } = orderParams;

  const bodyParams =
  {
    processAlias,
    transition: requestTransition,
    params: {
      stockReservationQuantity: quantity,
      listingId,
      protectedData: { isFreeLesson, listingDataMail },
      metadata: { userPackages }
    },
  };

  const queryParams = {
    include: ['booking', 'customer'],
    expand: true,
  };

  const handleError = e => {
    dispatch(initiateFreeLessonError(storableError(e)));
    const transactionIdMaybe = {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      // listingId: orderParams.listingId.uuid,
      quantityMaybe: quantity,
      ...orderData,
    });
    throw e;
  };

  const orderData = { quantity, isFreeLesson };


  return updateStrapiFreeLessonsData({ isLessonUsed: true, id: isFreeLesson.id })
    .then((response) => {
      console.log(response, '&&& updateStrapiFreeLessonsData &&& => response');
      return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
    })
    .then(async (res) => {
      const entities = denormalisedResponseEntities(res);
      const order = entities[0];
      dispatch(initiateFreeLessonSuccess(order));

      // update metaData 
      await onCreateUserPackages({ ...userPackages, transactionId: res.data.data.id.uuid })

      // notification 
      try {
        const { authorId, title = "", userName } = listingDataMail || {};
        await onCreateNotification({
          external_id: [authorId],
          contentTitle: userName + " ha usado la " + title,
          heading: "Lección gratuita usada",
          web_url: REACT_APP_MARKETPLACE_ROOT_URL + "/new-order/" + order.id.uuid
        })
      } catch (e) {
        console.log('&&& onCreateNotification Error &&& => onFreeLessonIntiateOrder 351', e);
      }

      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return res;
    })
    .catch(handleError);
};

export const onCashLessonIntiateOrder = (
  orderParams,
  processAlias,
  requestTransition,
) => (dispatch, getState, sdk) => {
  try {

    dispatch(initiateCashLessonRequest());

    const { quantity, userPackages, listingId, listingDataMail } = orderParams;

    const bodyParams =
    {
      processAlias,
      transition: requestTransition,
      params: {
        stockReservationQuantity: quantity,
        listingId,
        protectedData: { userPackages, isCashLesson: true, quantity, listingDataMail },
        metadata: { userPackages }
      },
    };

    const queryParams = {
      include: ['booking', 'customer'],
      expand: true,
    };

    const handleError = e => {
      dispatch(initiateCashLessonError(storableError(e)));
      const transactionIdMaybe = {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        // listingId: orderParams.listingId.uuid,
        quantityMaybe: quantity,
        ...orderData,
      });
      throw e;
    };

    const orderData = { quantity, isCashLesson: true };

    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(async (res) => {
        const entities = denormalisedResponseEntities(res);
        const order = entities[0];
        // notification 
        try {
          const { authorId = "11", title = "", userName } = listingDataMail || {};
          await onCreateUserPackages({ ...userPackages, transactionId: res.data.data.id.uuid })
          await onCreateNotification({
            external_id: [authorId],
            contentTitle: userName + " ha comprado:  " + title,
            heading: "¡Has hecho una nueva venta!",
            web_url: REACT_APP_MARKETPLACE_ROOT_URL + "/new-order/" + order.id.uuid
          })
        } catch (e) {
          console.log('&&& onCreateNotification Error &&& => onFreeLessonIntiateOrder 351', e);
        }

        dispatch(initiateCashLessonSuccess(order));

        return res;
      })
      .catch(handleError);
  }
  catch (e) {
    return dispatch(initiateCashLessonError(storableError(e)));
  }
};

// listingId, title, singlePrice, totalPrice,totalQuantity,joinedSessions:[],transferSessions:[],type: "ACTIVE",price ,quantity
//  if isUpdate props is comming the we are update the userPackages // isUpdateType == "JOIN" , "TRANSFER"
export const onUpdateMetaData = (metaParams, isUpdateType = false) => async (dispatch, getState, sdk) => {
  const { transactionId, transferPackages = [], userPackages = [], joinedSessions = false, transferSessions = false, listingId } = metaParams;
  let newUserPackages = [...userPackages];
  let newTransferPackages = [...transferPackages];

  // when JOIN a Session  
  if (isUpdateType && isUpdateType == "JOIN" && joinedSessions && listingId) {
    newUserPackages = newUserPackages.map((st) => {
      if (st.listingId == listingId) {
        const quantityIs = ((+st.quantity) - 1)
        const priceIs = ((+st.price) - (+st.singlePrice));
        const prevJoinedSessions = st.joinedSessions || [];
        return {
          ...st,
          joinedSessions: [...prevJoinedSessions, joinedSessions].map((item) => ({
            joiningSessionGuest: item.joiningSessionGuest || [],
            start: item.start,
            end: item.end,
            additionalQuestions: item.additionalQuestions,
            currentListingId: item.currentListingId,
            joinedSessionTransactionId: item.joinedSessionTransactionId || false,
          })),
          type: quantityIs < 1 ? "INACTIVE" : "ACTIVE",
          price: priceIs < 1 ? 0 : priceIs,
          quantity: quantityIs < 1 ? 0 : quantityIs
        }
      } else {
        return st;
      }
    })
  }

  // when CANCEL a Session  
  // params: {transactionId, transferPackages = [], userPackages = [], listingId , joinedSessionTransactionId} , "CANCEL"

  if (isUpdateType && isUpdateType == "CANCEL" && newUserPackages.length && metaParams.joinedSessionTransactionId) {

    newUserPackages = newUserPackages.map((st) => {
      if (st.listingId == listingId) {
        return {
          ...st,
          joinedSessions: st.joinedSessions.filter((item) => item.joinedSessionTransactionId != metaParams.joinedSessionTransactionId),
          type: "ACTIVE",
          price: (+st.price) + 1,
          quantity: (+st.quantity) + 1,
        }
      } else {
        return st;
      }
    })
  }


  // when TRANSFER a Package update the userMetaData  
  else if (isUpdateType && isUpdateType == "TRANSFER" && transferSessions) {

    newTransferPackages = newUserPackages.filter((st) => st.listingId == listingId)
    newUserPackages = newUserPackages.filter((st) => st.listingId != listingId);
  }

  else {
    newUserPackages = newUserPackages.map((st) => ({ ...st, transactionId: transactionId }))
  }

  if (newUserPackages && newUserPackages.length) {
    const params = {
      id: transactionId,
      metadata: {
        userPackages: newUserPackages,
        transferPackages: newTransferPackages
      }
    }
    return updateMetaDataApi(params).then(res => {
      return { userPackages: newUserPackages, transferPackages: newTransferPackages }
    })
      .catch((e) => {
        console.error(e, '&&& &&& => e');
        return storableError(e);
      }
      );
  }

  else {
    return { userPackages: newUserPackages, transferPackages: newTransferPackages }
  }

}

// if sessionBooking props is comming the we are joining a session 
export const confirmPayment = (transactionId, transitionName, transitionParams = {}, listing) => async (
  dispatch,
  getState,
  sdk
) => {

  dispatch(confirmPaymentRequest());

  const {
    userPackages,
    lessonCategory,
    formValuesGuest = {}
  } = transitionParams;

  let createSubscriptionParams = false;
  if (lessonCategory && lessonCategory === COURSE_LESSON_CATEGORY) {
    createSubscriptionParams = await onCreateSubscription({ ...transitionParams, transactionId: transactionId });
  }

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: lessonCategory === COURSE_LESSON_CATEGORY ? { protectedData: { stripePaymentIntents: null, formValuesGuest: formValuesGuest } } : transitionParams.type == "updateMeta" ? {} : transitionParams,
  };

  let confirmPaymentResponse;

  return sdk.transactions
    .transition(bodyParams)
    .then(async (response) => {
      confirmPaymentResponse = response.data.data
      const addUserPackage = [];
      if (lessonCategory && [SINGLE_LESSON_CATEGORY, PACK_OF_LESSON_CATEGORY].includes(lessonCategory)) {
        console.log('&&& &&& => we are adding package in stapi');
        const { guestFirstName = "", guestPhoneNumber = "", guestLastName = "" } = formValuesGuest || {};
        const guestJsonValues = formValuesGuest && formValuesGuest.guest ? { guestFirstName, guestPhoneNumber, guestLastName, isGuest: "guest" } : {};
        const userPackageJson = { ...userPackages, transactionId: response.data.data.id.uuid, ...guestJsonValues };
        console.log(userPackageJson, '&&& &&& => userPackageJson');
        addUserPackage.push(onCreateUserPackages(userPackageJson))
        console.log('&&& &&& => sucessfully added packages in strapi');
      }

      return Promise.all[addUserPackage];
    })
    .then(() => {
      const order = confirmPaymentResponse;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })

    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      // listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
