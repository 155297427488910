import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { ORDER_INBOX_PAGE_LESSON, ORDER_INBOX_PAGE_SCHOOL, ORDER_INBOX_PAGE_STUDENT, SCHOOL_TYPE, USER_ROLE_INSTRUCTOR, propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  FieldToggleBar,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import css from './TopbarDesktop.module.css';

const desktopMenu = (userType, currentPageClass) => {

  if (userType && userType==SCHOOL_TYPE) {
    return [
      {
        key: "SchoolPlanning",
        className: classNames(css.yourListingsLink, currentPageClass('SchoolPlanning')),
        nameLink: "SchoolPlanning",
        name: <FormattedMessage id="TopbarDesktop.allLesson" />
      },
      {
        key: "ManageListingsPage",
        className: classNames(css.yourListingsLink, currentPageClass('ManageListingsPage')),
        nameLink: "ManageListingsPage",
        name: <FormattedMessage id="UserNav.allLessonPage" />
      },
      {
        key: "InboxPage",
        className: classNames(css.yourListingsLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_SCHOOL, type: ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "SchoolInvitePage",
        className: classNames(css.yourListingsLink, currentPageClass('SchoolInvitePage')),
        nameLink: "SchoolInvitePage",
        name: <FormattedMessage id="TopbarDesktop.InviteUsersPage" />,
      },
      {
        key: "SchoolInstructorPage",
        className: classNames(css.yourListingsLink, currentPageClass('SchoolInstructorPage')),
        nameLink: "SchoolInstructorPage",
        name: <FormattedMessage id="TopbarDesktop.SchoolInstructorPage" />,
      },
      {
        key: "ProfileSettingsPage",
        className: classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      }, {
        key: "AccountSettingsPage",
        className: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },

    ]
  }
  // Equipo (SchoolInstructorPage)
  else if(userType && userType==USER_ROLE_INSTRUCTOR){
    // Planificación
    return [
      {
        key: "InstructorPanningPage",
        className: classNames(css.yourListingsLink, currentPageClass('InstructorPanningPage')),
        nameLink: "InstructorPanningPage",
        name: <FormattedMessage id="TopbarDesktop.InstructorPanningPage" />,
        params:{}
      },
      {
        key: "InboxPage",
        className: classNames(css.yourListingsLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_STUDENT, type: ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "BookingPage",
        className: classNames(css.yourListingsLink, currentPageClass('BookingPage')),
        nameLink: "BookingPage",
        name: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        params: { tab: "pending" }
      },
      {
        key: "StudentSchool",
        className: classNames(css.yourListingsLink, currentPageClass('DashBoardPage')),
        nameLink: "StudentSchool",
        name: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />
      }, {
        key: "ProfileSettingsPage",
        className: classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      }, {
        key: "AccountSettingsPage",
        className: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },
    ]
  }

  else {


    return [
      {
        key: "InboxPage",
        className: classNames(css.yourListingsLink, currentPageClass('InboxPage')),
        nameLink: "InboxPage",
        name: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        params: { tab: ORDER_INBOX_PAGE_STUDENT, type: ORDER_INBOX_PAGE_LESSON }
      },
      {
        key: "BookingPage",
        className: classNames(css.yourListingsLink, currentPageClass('BookingPage')),
        nameLink: "BookingPage",
        name: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        params: { tab: "pending" }
      },
      {
        key: "StudentSchool",
        className: classNames(css.yourListingsLink, currentPageClass('DashBoardPage')),
        nameLink: "StudentSchool",
        name: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />
      }, {
        key: "ProfileSettingsPage",
        className: classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage')),
        nameLink: "ProfileSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      }, {
        key: "AccountSettingsPage",
        className: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
        nameLink: "AccountSettingsPage",
        name: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      },
    ]
  }

}

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
    history,
    chatNotification
  } = props;

  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const routeConfiguration = useRouteConfiguration();
  const routes = routeConfiguration;
  const classes = classNames(rootClassName || css.root, className);
  const userType = currentUser && currentUser?.attributes?.profile?.publicData?.userListingType;
  const publicData = currentUser && currentUser?.attributes?.profile?.publicData;
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = chatNotification ? <div className={css.notificationDot} /> : null;
  // notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="ChatPageInbox"
    // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;
  // authenticatedOnClientSide ? (
  //   <NamedLink
  //     className={css.inboxLink}
  //     name="InboxPage"
  //     params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //   >
  //     <span className={css.inbox}>
  //       <FormattedMessage id="TopbarDesktop.inbox" />
  //       {notificationDot}
  //     </span>
  //   </NamedLink>
  // ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };


  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {desktopMenu(userType, currentPageClass).map((st) => <MenuItem key={st.key}>
          <NamedLink
            className={st.className}
            name={st.nameLink}
            params={st.params || {}}
          >
            <span className={css.menuItemBorder} />
            {st.name}
          </NamedLink>
        </MenuItem>)}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {/* {search} */}

      <div className={css.rightBox}>
        {/* {userType ?
          <div className={css.createListingLink}>
            <NamedLink
              className={css.createListing}
              name={"SchoolDashBoardPage"}
            >
              <FormattedMessage id="TopbarDesktop.listYourSession" />
            </NamedLink>
          </div>
          : null} */}
        {inboxLink}
        {profileMenu}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
