export const makeStrapiFilters = (filterArr)=>{
  return (filterArr && filterArr.length) 
  ? `${filterArr.map((st)=> {
   const { type , keyName , keyFilter , keyValue } = st;
   return keyFilter ? `${type}[${keyName}][${keyFilter}]=${keyValue}` : `${type}[${keyName}]=${keyValue}`;
  } ).join("&")}`
  : "" 
};

export const schoolInstructorFilterArray = (userId) => {
  const schoolInstructorFilters = [
    { type: "pagination", keyName: "page", keyFilter: "", keyValue: 1 },
    { type: "pagination", keyName: "pageSize", keyFilter: "", keyValue: 100 },
    { type: "sort", keyName: 0, keyFilter: "", keyValue: "id:asc" }, // desc
    { type: "filters", keyName: "schoolId", keyFilter: "$eq", keyValue: userId },
    { type: "filters", keyName: "isDeleted", keyFilter: "$eq", keyValue: false },
    { type: "filters", keyName: "platformLogin", keyFilter: "$eq", keyValue: true }
  ]
  return makeStrapiFilters(schoolInstructorFilters);
};

export const STRAPI_USER_PACKAGES_URL = "user-packages";
export const STRAPI_ADDITIONAL_QUESTIONS_URL = "additional-questions";
export const STRAPI_CHAT_URL = "chats";
export const STRAPI_CHAT_LIST_URL = "chat-lists";
export const STRAPI_DELETE_SESSION_URL = "delete-sessions";
export const STRAPI_FREE_LESSONS_STRAPI_URL = "free-lessons";
export const STRAPI_SCHOOL_INSTRUCTORS_URL = "school-instructors";
export const STRAPI_INSTRUCTORS_SESSIONS_URL = "instructor-sessions";
export const STRAPI_VALIDATE_SCHOOL_MAIL_URL = "validate-school-mails";
export const STRAPI_SESSION_URL = "sessions";



// schemas <<<<<<<<<<<<< STRAPI_USER_PACKAGES_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_ADDITIONAL_QUESTIONS_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_CHAT_LIST_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_CHAT_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_DELETE_SESSION_URL >>>>>>>>>>> 

const STRAPI_DELETE_SESSION_SCHEMA = {
    sessionId:"string",
    sessionDate:"string",
    userId:"string",
    listingId:"",
    mailSend:"",
    reason:"",
    info:{
            "sessionDetails": {
              "email": "marryschool1@yopmail.com",
              "schoolName": "international school",
              "sessionDate": "17-06-2024",
              "sessionTitle": "Golf Multiple Day Session ",
              "currentDateIs": 1718562600,
              "sessionEndTime": "01:20",
              "sessionLocation": "New",
              "schoolProfileUrl": "http://localhost:3000/u/6577d807-dd72-43e4-b3e0-2ed3ebc8a47a",
              "sessionStartTime": "00:40"
            },
            "userPackagesIds": [
              {
                "quantity": 1,
                "userPackageId": "4"
              }
            ],
            "updatedPackagesIds": [
              {
                "prevQuantity": 0,
                "addedQuantity": 1,
                "userPackageId": "4"
              }
            ],
            "sessionTransactionIds": [
              9
            ]
    }
};

// schemas <<<<<<<<<<<<< STRAPI_FREE_LESSONS_STRAPI_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_SCHOOL_INSTRUCTORS_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_INSTRUCTORS_SESSIONS_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_VALIDATE_SCHOOL_MAIL_URL >>>>>>>>>>> 

// schemas <<<<<<<<<<<<< STRAPI_SESSION_URL >>>>>>>>>>>  
// {
//     userId:"string",
//     sessionId:"string",
//     lessonId:"string",

//     // check the session type(single , multiple)
//     periodicity:"string",

//     // date of session YYYY-MM-DD
//     sessionDate:"string", 
//     sessionStartTimeUnix:"number", 
//     sessionEndTimeUnix:"number", 

//     // type of session is cancel ,
//     sessionType:"string", // ("cancel"  "session")
//     cancelMinQty:"number", 
//     cancelTime:"number",
//     cancelStatus:"string", // "pending"  "done"

//     // check we have to add new session or not   
//     addNewSessionStatus:periodicity =="single" ? "done" : "pending", // string 

//     // for attendance purpose we use this we add this during user joins a session
//     totalUserJoined:0, // number >> total user joined  
//     sessionJoinQty, // number >> total session qty 

//     additional:{
//       sessionName:attributes.title,// string
//     }
//     status:"string" open,delete
    
//   }

