import moment from 'moment';

import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { onCreateAvalabityException, onCreateDeletedSession, onCreateMultipleNotification, onGetSessionTransaction } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { parse } from '../../util/urlHelpers';
import { queryInstructors } from '../SchoolInstructorPage/SchoolInstructorPage.duck';
import { schoolInstructorFilterArray } from '../../config/configStrapiUrl';

const REACT_APP_MARKETPLACE_ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const RESULT_PAGE_SIZE = 100
// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SingleDashBoardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SingleDashBoardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SingleDashBoardPage/FETCH_LISTINGS_ERROR';
export const EXCEPTION_UPDATE_REQUEST = 'app/SingleDashBoardPage/EXCEPTION_UPDATE_REQUEST';
export const SLOTS_UPDATE_REQUEST = 'app/SingleDashBoardPage/SLOTS_UPDATE_REQUEST';

export const QUERY_SESSION_TRANSACTION_REQUEST = 'app/SingleDashBoardPage/QUERY_SESSION_TRANSACTION_REQUEST';
export const QUERY_SESSION_TRANSACTION_SUCCESS = 'app/SingleDashBoardPage/QUERY_SESSION_TRANSACTION_SUCCESS';
export const QUERY_SESSION_TRANSACTION_ERROR = 'app/SingleDashBoardPage/QUERY_SESSION_TRANSACTION_ERROR';

export const DELETE_SESSION_REQUEST = 'app/SingleDashBoardPage/DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'app/SingleDashBoardPage/DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'app/SingleDashBoardPage/DELETE_SESSION_ERROR';

const { UUID } = sdkTypes;

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryListingsError: null,
  listings: {},
  timeSlots: [],
  exceptionUpdateRequest: false,
  querySessionTransactionRequest: false,
  userSessionJoinedTransactions: [],
  querySessionTransactionError: false,
  deleteSessionRequest: false
};


const SingleDashBoardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        queryListingsError: null
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        ...payload,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };
    case EXCEPTION_UPDATE_REQUEST:
      return {
        ...state,
        exceptionUpdateRequest: payload
      };
    case SLOTS_UPDATE_REQUEST:
      return {
        ...state,
        timeSlots: payload
      };

    case QUERY_SESSION_TRANSACTION_REQUEST:
      return {
        ...state,
        querySessionTransactionRequest: payload,
        userSessionJoinedTransactions: [],
        querySessionTransactionError: false,
      }

    case QUERY_SESSION_TRANSACTION_SUCCESS:
      return {
        ...state,
        querySessionTransactionRequest: false,
        userSessionJoinedTransactions: payload,
        querySessionTransactionError: false,
      }
    case QUERY_SESSION_TRANSACTION_ERROR:
      return {
        ...state,
        querySessionTransactionRequest: false,
        userSessionJoinedTransactions: [],
        querySessionTransactionError: payload,
      }

    case DELETE_SESSION_REQUEST:
      return {
        ...state,
        deleteSessionRequest: true
      }

    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        deleteSessionRequest: false,
        listing: payload
      }

    case DELETE_SESSION_ERROR:
      return {
        ...state,
        deleteSessionRequest: false
      }


    default:
      return state;
  }
};

export default SingleDashBoardPageReducer;

// ================ Selectors ================ //


// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const querySessionTransactionRequest = (data) => ({
  type: QUERY_SESSION_TRANSACTION_REQUEST,
  payload: data,
})

export const querySessionTransactionSuccess = (data) => ({
  type: QUERY_SESSION_TRANSACTION_SUCCESS,
  payload: data,
})

export const querySessionTransactionError = (data) => ({
  type: QUERY_SESSION_TRANSACTION_ERROR,
  payload: data,
})


export const UpdateListingExceptionRequest = (data) => ({
  type: EXCEPTION_UPDATE_REQUEST,
  payload: data,
})

export const deleteSessionRequest = (data) => ({
  type: DELETE_SESSION_REQUEST,
});

export const deleteSessionSuccess = (data) => ({
  type: DELETE_SESSION_SUCCESS,
  payload: data
})

export const deleteSessionError = (data) => ({
  type: DELETE_SESSION_ERROR,
})

export const deleteSession = (params) => async (dispatch, getState, sdk) => {
  dispatch(deleteSessionRequest());
  const { listingParams, deleteParams, sendSignalNotification } = params;
  return onCreateDeletedSession(deleteParams)
    .then(response => {
      return sdk.ownListings.update(listingParams, { expand: true });
    })
    .then(async (response) => {

      try {
        const currentUser = getState().user.currentUser;
        const { title } = response.data.data.attributes;
        const url = REACT_APP_MARKETPLACE_ROOT_URL + "/bookings/cancelled";
        if (sendSignalNotification) {

          // await onCreateMultipleNotification({
          //   id: currentUser.id.uuid,
          //   contentTitle: "Hemos tenido que cancelar " + title,
          //   heading: currentUser?.attributes?.profile?.publicData?.schoolName,
          //   web_url: url,
          //   type: "SESSION",
          //   sendSignalNotification
          // })
        }

      } catch (e) {
        console.log('&&& onCreateMultipleNotification Error &&& => requestPublishListingDraft 707', e);
      }

      dispatch(deleteSessionSuccess(response.data.data));
      return response;
    })
    .catch((e) => {
      dispatch(deleteSessionError());
      return storableError(e);
    });
}

export const querySessionTransaction = (params) => async (dispatch, getState, sdk) => {
  try {
    const { listingId, time, userId } = params;
    dispatch(querySessionTransactionRequest(time));
    const page = 1;
    const pageSize = RESULT_PAGE_SIZE;

    const searchFilter = `pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[listingId][$eq]=${listingId}&filters[authorId][$eq]=${userId}&filters[date][$eq]=${time}`;

    return onGetSessionTransaction({ searchFilter }).then(res => {
      dispatch(querySessionTransactionSuccess(res.data));
    })
      .catch((e) => {
        console.log(e, '&&& &&& => e');
        dispatch(querySessionTransactionError(storableError(e)));
        return storableError(e);
      })
  }

  catch (e) {
    console.log(e, '&&& &&& => e');
    dispatch(querySessionTransactionError(storableError(e)));
    return storableError(e);
  }

}


const getAllDaysTimeSlots = (startDateUnix, endDateUnix, daysOfWeek) => {
  // Convert Unix timestamps to Moment objects
  const currentDate = moment(startDateUnix * 1000);
  const endDate = moment(endDateUnix * 1000);
  const matchingDays = [];
  while (currentDate.isSameOrBefore(endDate, 'day')) {
    // Check if the day of the week matches any of the specified days
    if (daysOfWeek.includes(currentDate.format('dddd'))) {
      matchingDays.push({ currentDateIs: currentDate.unix(), day: currentDate.format('dddd') });
    }
    currentDate.add(1, 'day');
  }
  return matchingDays;
};

// Throwing error for new (loadData may need that info)
export const queryListings = (params, userId) => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  try {
    const { calendarDate = false } = params;
    const listingId = new UUID(params.listingId);
    return sdk.listings.show({ id: listingId, include: ['author', 'author.profileImage', 'images'] })
      .then(async (response) => {
        const { dayAvailability = [], startDate, endDate } = response.data?.data?.attributes?.publicData || {};

        const timeSlotsIs = (startDate && endDate && dayAvailability && dayAvailability.length)
          ? getAllDaysTimeSlots(moment(startDate).unix(), moment(endDate).unix(), dayAvailability.map((st) => st.day))
          : [];

        // query users transactions 
        if (timeSlotsIs && timeSlotsIs.length) {
          await dispatch(querySessionTransaction({ listingId: params.listingId, time: calendarDate ? moment(calendarDate).format('DD-MM-YYYY') : moment(timeSlotsIs[0].currentDateIs * 1000).format('DD-MM-YYYY'), userId: userId }));
        }

        dispatch(queryListingsSuccess({ listing: response.data.data, timeSlots: timeSlotsIs }));
        return response;
      })
      .catch(e => {
        dispatch(queryListingsError(storableError(e)));
        throw e;
      });
  } catch (e) {
    dispatch(queryListingsError(storableError(e)));
    return e;
  }
};

export const UpdateListingException = (params, timeSlots, availabiltyException, sendSignalNotification, notificationParams) => async (dispatch, getState, sdk) => {
  dispatch(UpdateListingExceptionRequest(true));

  if (availabiltyException) {
    await onCreateAvalabityException(availabiltyException);
  }

  return sdk.ownListings
    .update(params, { expand: true })
    .then(async (response) => {
      try {
        const currentUser = getState().user.currentUser;
        const { title } = response.data.data.attributes;
        const url = REACT_APP_MARKETPLACE_ROOT_URL + `/bookings/pending`;
        const notificationPromise = [];

        const {
          studentUpdateCheck,
          deletedInstructors,
          newInstructorsAdded
        } = notificationParams;

        sendSignalNotification && studentUpdateCheck && notificationPromise.push(onCreateMultipleNotification({
          id: currentUser.id.uuid,
          contentTitle: "Se ha editado " + title,
          heading: currentUser?.attributes?.profile?.publicData?.schoolName,
          web_url: url,
          type: "SESSION",
          sendSignalNotification
        }));

        // Se te ha asignado: [session name]
        newInstructorsAdded && newInstructorsAdded.length && notificationPromise.push(onCreateMultipleNotification({
          id: currentUser.id.uuid,
          contentTitle: "Se te ha asignado: " + title,
          heading: currentUser?.attributes?.profile?.publicData?.schoolName,
          web_url: url,
          type: "SESSION",
          sendSignalNotification: newInstructorsAdded
        }));

        await Promise.all(notificationPromise);

      } catch (e) {
        console.log('&&& onCreateMultipleNotification Error &&& => requestPublishListingDraft 707', e);
      }

      dispatch(UpdateListingExceptionRequest(false));
      dispatch(queryListingsSuccess({ listing: response.data.data, timeSlots: timeSlots }));
      return response;
    }).catch(e => {
      console.log(e, "e");
      dispatch(UpdateListingExceptionRequest(false));
      return e;
    });
}

// exceptionDays deletetedDays 

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  const queryParams = parse(search);
  const { calendarDate = false } = queryParams || {};

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      const userId = currentUser.id.uuid;
      // dispatch(queryUserTransactions(pageParams));
      if (userId) {
        dispatch(queryListings({ ...params, calendarDate }, userId))
      }
      // const searchFilter = `sort[0]=id:asc&pagination[page]=${1}&pagination[pageSize]=${100}&filters[schoolId][$eq]=${userId}&filters[platformLogin][$eq]=${true}`;
      const searchFilter = schoolInstructorFilterArray(userId);
      dispatch(queryInstructors("", userId, true, searchFilter));
    });
};

