import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { ORDER_INBOX_PAGE_LESSON, ORDER_INBOX_PAGE_SCHOOL, ORDER_INBOX_PAGE_STUDENT, SCHOOL_TYPE, USER_ROLE_INSTRUCTOR } from '../../util/types';

import css from './UserNav.module.css';

const userTabs = (userType, currentPage) => {

  if (userType && userType==SCHOOL_TYPE) {
    return [

      {
        text: <FormattedMessage id="TopbarDesktop.allLesson" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'SchoolPlanning',
        linkProps: {
          name: 'SchoolPlanning',
        },
      },
      {
        text: <FormattedMessage id="UserNav.allLessonPage" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'ManageListingsPage',
        linkProps: {
          name: 'ManageListingsPage',
        },
      },
      {
        text: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'InboxPage',
        linkProps: {
          name: 'InboxPage',
          params: { tab: ORDER_INBOX_PAGE_SCHOOL, type: ORDER_INBOX_PAGE_LESSON }
        }
      },
      {
        text: <FormattedMessage id="TopbarDesktop.InviteUsersPage" />,
        selected: currentPage === 'SchoolInvitePage',
        linkProps: {
          name: 'SchoolInvitePage',
        },
      },
      {
        text:<FormattedMessage id="TopbarDesktop.SchoolInstructorPage" />,
        selected: currentPage === 'SchoolInstructorPage',
        linkProps: {
          name: 'SchoolInstructorPage',
        },
      },
      {
        text: <FormattedMessage id="UserNav.profileSettings" />,
        selected: currentPage === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="UserNav.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
    ];
  } 

  else if(userType && userType==USER_ROLE_INSTRUCTOR){
    return [
      {
        text: <FormattedMessage id="TopbarDesktop.InstructorPanningPage" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'InstructorPanningPage',
        linkProps: {
          name: 'InstructorPanningPage',
        }
      },
      {
        text: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'InboxPage',
        linkProps: {
          name: 'InboxPage',
          params: { tab: ORDER_INBOX_PAGE_STUDENT, type: ORDER_INBOX_PAGE_LESSON }
        }
      },
      {
        text: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        selected: currentPage === 'BookingPage',
        linkProps: {
          name: 'BookingPage',
          params: { tab: "pending" }
        },
      },
      {
        text: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />,
        selected: currentPage === "StudentSchool",
        linkProps: {
          name: "StudentSchool"
        },
      },
      {
        text: <FormattedMessage id="UserNav.profileSettings" />,
        selected: currentPage === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="UserNav.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
    ];
  }
  
  
  else {
    return [
      {
        text: <FormattedMessage id="TopbarDesktop.InboxPage" />,
        // <FormattedMessage id="UserNav.yourListings" />
        selected: currentPage === 'InboxPage',
        linkProps: {
          name: 'InboxPage',
          params: { tab: ORDER_INBOX_PAGE_STUDENT, type: ORDER_INBOX_PAGE_LESSON }
        }
      },
      {
        text: <FormattedMessage id="TopbarDesktop.BookingsPage" />,
        selected: currentPage === 'BookingPage',
        linkProps: {
          name: 'BookingPage',
          params: { tab: "pending" }
        },
      },
      {
        text: <FormattedMessage id="TopbarDesktop.YourSchoolsPage" />,
        selected: currentPage === "StudentSchool",
        linkProps: {
          name: "StudentSchool"
        },
      },
      {
        text: <FormattedMessage id="UserNav.profileSettings" />,
        selected: currentPage === 'ProfileSettingsPage',
        disabled: false,
        linkProps: {
          name: 'ProfileSettingsPage',
        },
      },
      {
        text: <FormattedMessage id="UserNav.accountSettings" />,
        selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
        disabled: false,
        linkProps: {
          name: 'ContactDetailsPage',
        },
      },
    ];
  }
}

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const userType = currentUser && currentUser?.attributes?.profile?.publicData?.userListingType;

  const tabs = userTabs(userType, currentPage)
  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
